import React from "react";
import PartnersOverview from "../overviews/partners";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

const query = graphql`
	query PartnersQuery {
		cms {
			partners {
				partnerlogo {
					hexCode
					logo
				}
				show_approaches
				name
			}
		}
	}
`;

const ApproachLogoSlider: React.FC<{ theme: string }> = ({ theme }) => {
	const r = useStaticQuery(query);
	const location = useLocation();
	const currentSlug = location.pathname.split("/").filter(Boolean).pop();
	const partners = r.cms.partners.filter(p =>
		p.show_approaches.includes(currentSlug)
	);
	return (
		<PartnersOverview
			theme={theme}
			partners={partners}
			heading=""
			bodyText=""
		/>
	);
};

export default ApproachLogoSlider;
