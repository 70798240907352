import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { Insight } from "../../../../interfaces/common";

import classes from "./styles.module.scss";

import FeaturedInsightsGrid from "../../grids/featured-insights";

const InsightsLandingHero: React.FC<{
	heading: string;
	featuredInsights: Insight[];
}> = ({ heading, featuredInsights }) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div className={classes.wrapper}>
				<div className={classes.heading}>
					<h1>{heading}</h1>
				</div>
				<div className={classes.categories}>
					<ul>
						<li className="eyebrow">
							<Link to="/insights/">All</Link>
						</li>
						<li className="eyebrow">
							<Link to="/insights/company/">Company</Link>
						</li>
						<li className="eyebrow">
							<Link to="/insights/shared-learning/">Shared Learning</Link>
						</li>
						<li className="eyebrow">
							<Link to="/insights/interviews/">Interviews</Link>
						</li>
					</ul>
				</div>
			</div>
			<FeaturedInsightsGrid insights={featuredInsights} />
		</div>
	);
};

export default InsightsLandingHero;
