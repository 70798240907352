import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import DetailPageHero from "../index";

import CTAArrowRound from "../../../ui/ctas/arrow-round";
import LogoIcon from "../../../../assets/vectors/logo-icon.svg";

const CareerDetailHero: React.FC<{
	heading: string;
	image: string;
	imageAlt: string;
	description: string;
	date: string;
	cta: string;
	ctaURL: string;
	positionTitle: string;
}> = ({
	heading,
	image,
	imageAlt,
	description,
	date,
	cta,
	ctaURL,
	positionTitle,
}) => (
	<DetailPageHero
		heading={heading}
		backgroundImage={image}
		backgroundImageAlt={imageAlt}
		headingSize="mega"
		blur={false}
	>
		<div className={classes.logo}>
			<LogoIcon />
		</div>
		<div className={classes.detailsText}>
			<p className="metadata">{date}</p>
			<p className="h5">{positionTitle}</p>
			<div className="eyebrow">{parse(description)}</div>
		</div>
		<div className={classes.cta}>
			<p className="metadata">Think you're a great fit? Reach out.</p>
			<CTAArrowRound label={cta} mailto={ctaURL} />
		</div>
	</DetailPageHero>
);

export default CareerDetailHero;
