import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroCaseStudy from "../components/heroes/detail-pages/case-study";
import CaseStudyIntro from "../components/text-blocks/case-study-intro";
import TextBlockLargeImage from "../components/text-blocks/large-image";
import DesignCompsCarousel from "../components/carousels/design-comps";
import DetailsWeLove from "../components/details-we-love";
import DescriptionList from "../components/text-blocks/description-list";
import Testimonial from "../components/testimonials/testimonial";
import RelatedContent from "../components/related-content";

const CaseStudyDetail: React.FC = ({ location, pageContext }) => {
	const { data, noIndex } = pageContext;

	const {
		seo,
		caseStudyHero,
		caseStudyProjectDetail,
		caseStudyProjectIntro,
		caseStudyProjectChallenge,
		caseStudyProjectDesign,
		caseStudyProjectTechnologies,
		caseStudyDetailsWeLove,
		caseStudyProjectBranding,
		caseStudyProjectStrategy,
		casestudytestimonials,
		caseStudyProjectResult,
		caseStudyRelatedProjects,
	} = data;

	return (
		<>
			<Helmet>
				<meta name="twitter:label1" value="Project Name" />
				<meta
					name="twitter:data1"
					value={caseStudyProjectDetail.project_title}
				/>
				{noIndex && <meta name="robots" content="noindex" />}
				<meta name="twitter:label2" value="Project Url" />
				<meta name="twitter:data2" value={caseStudyProjectDetail.project_url} />
			</Helmet>

			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="transparent">
				<HeroCaseStudy
					heading={caseStudyHero.headline}
					heroImage={caseStudyHero.image}
					projectTitle={caseStudyProjectDetail.project_title}
					projectUrl={caseStudyProjectDetail.project_url}
					services={caseStudyProjectDetail.casestudyprojectdetailservices}
					logo={caseStudyProjectDetail.client_logo}
					backgroundColor={caseStudyProjectIntro.hexCode}
					vimeoId={caseStudyHero.vimeoId}
				/>
				<div className="sections">
					<CaseStudyIntro
						introText={caseStudyProjectIntro.power_statement}
						backgroundColor={caseStudyProjectIntro.hexCode}
						logo={caseStudyProjectIntro.client_logo}
						image={caseStudyProjectIntro.image}
						imageAlt={caseStudyProjectIntro.image_alt}
						vimeoId={caseStudyProjectIntro.vimeoId}
					/>
					{caseStudyProjectChallenge &&
						caseStudyProjectChallenge.length > 0 && (
							<TextBlockLargeImage
								heading={caseStudyProjectChallenge[0].headline}
								body={caseStudyProjectChallenge[0].body_copy}
								image={caseStudyProjectChallenge[0].image}
								alt={caseStudyProjectChallenge[0].alt}
								vimeoId={caseStudyProjectChallenge[0].vimeoId}
								fullBleedImage={caseStudyProjectChallenge[0].full_bleed_image}
							/>
						)}

					{caseStudyProjectBranding && caseStudyProjectBranding.length > 0 && (
						<TextBlockLargeImage
							heading={caseStudyProjectBranding[0].headline}
							body={caseStudyProjectBranding[0].body_copy}
							image={caseStudyProjectBranding[0].image}
							alt={caseStudyProjectBranding[0].alt}
							vimeoId={caseStudyProjectBranding[0].vimeoId}
							fullBleedImage={caseStudyProjectBranding[0].full_bleed_image}
						/>
					)}

					{caseStudyProjectStrategy && caseStudyProjectStrategy.length > 0 && (
						<TextBlockLargeImage
							heading={caseStudyProjectStrategy[0].headline}
							body={caseStudyProjectStrategy[0].body_copy}
							image={caseStudyProjectStrategy[0].image}
							alt={caseStudyProjectStrategy[0].alt}
							vimeoId={caseStudyProjectStrategy[0].vimeoId}
							fullBleedImage={caseStudyProjectStrategy[0].full_bleed_image}
						/>
					)}

					{caseStudyProjectDesign &&
						caseStudyProjectDesign.length > 0 &&
						caseStudyProjectDesign.map((gallery, i) => (
							<DesignCompsCarousel
								key={i}
								index={i}
								heading={gallery.headline}
								bodyText={gallery.body_copy}
								heroImage={gallery.image}
								heroImageAlt={gallery.alt}
								vimeoId={gallery.vimeoId}
								gapBetweenSlides={gallery.gap_between_slides}
								backgroundColor={gallery.background_color}
								galleryItems={gallery.caseStudyProjectDesignGallery}
							/>
						))}

					{caseStudyDetailsWeLove && (
						<DetailsWeLove
							heading={caseStudyDetailsWeLove.headline}
							galleryItems={
								caseStudyDetailsWeLove.caseStudyDetailsWeLoveGallery
							}
							gapBetweenSlides={caseStudyDetailsWeLove.gap_between_slides}
							isActive={!caseStudyProjectTechnologies}
						/>
					)}

					{caseStudyProjectTechnologies && (
						<DescriptionList
							heading={caseStudyProjectTechnologies.headline}
							bodyText={caseStudyProjectTechnologies.body_copy}
							listItems={
								caseStudyProjectTechnologies.caseStudyProjectTechnologies
							}
						/>
					)}

					{caseStudyProjectResult && (
						<TextBlockLargeImage
							heading={caseStudyProjectResult.headline}
							body={caseStudyProjectResult.body_copy}
							image={caseStudyProjectResult.image}
							alt={caseStudyProjectResult.alt}
							vimeoId={caseStudyProjectResult.vimeoId}
							imageFirst={true}
							fullBleedImage={caseStudyProjectResult.full_bleed_image}
						/>
					)}

					{casestudytestimonials && casestudytestimonials.length > 0 && (
						<Testimonial
							theme="dark"
							testimonials={casestudytestimonials}
							flushTop={false}
						/>
					)}
				</div>

				{caseStudyRelatedProjects && (
					<RelatedContent
						eyebrow={caseStudyRelatedProjects.eyebrow}
						heading={caseStudyRelatedProjects.headline}
						relatedItems={caseStudyRelatedProjects.caseStudyRelatedProjects}
						urlBase="/work"
					/>
				)}
			</Layout>
		</>
	);
};

export default CaseStudyDetail;
