// Format a number as two digits
const twoDigitFormat = num => {
	return ("0" + num).slice(-2);
};

// returns pixel value representing the offset from child element to root
// const { left, top } = getRootOffset(el);
const getRootOffset = (el, root = window) => {
	let parent = el;
	const bounds = {
		top: 0,
		left: 0,
	};

	do {
		if (!isNaN(parent.offsetTop)) {
			bounds.top += parent.offsetTop;
		}
		if (!isNaN(parent.offsetLeft)) {
			bounds.left += parent.offsetLeft;
		}
		parent = parent.offsetParent;
	} while (parent && parent !== root);

	return bounds;
};

// Relative element offset
const loop = (value, el) => {
	let offset = 0;
	let parent = el;
	do {
		if (!isNaN(parent[value])) offset += parent[value];
		parent = parent.offsetParent;
	} while (parent);
	return offset;
};

const getRelativeOffset = {
	left: el => loop("offsetLeft", el),
	top: el => loop("offsetTop", el),
};

// Bind events
const bindEvents = (cl, prefix = "_on") => {
	const events = {};
	const methods = Reflect.ownKeys(Reflect.getPrototypeOf(cl));
	for (const m of methods)
		if (m.indexOf(prefix) === 0) events[m] = cl[m].bind(cl);
	return events;
};

const clamp = (num, min, max) => {
	if (num <= min) {
		return min;
	}

	if (num >= max) {
		return max;
	}

	return num;
};

const formatServices = services => {
	const formatted = {};
	const flattened = [];

	services.forEach(service => {
		const category = service.category?.name;

		if (!formatted[category]) {
			formatted[category] = {};
			formatted[category].url = "";
			formatted[category].items = [];
		}

		formatted[category].url = service.category.url;
		formatted[category].items.push(service);
	});

	for (const [key, value] of Object.entries(formatted)) {
		const { url, items } = value;

		flattened.push({
			heading: key,
			url,
			listItems: items,
		});
	}

	return flattened;
};

const pathJoin = (parts, sep) => {
	var separator = sep || "/";
	var replace = new RegExp(separator + "{1,}", "g");
	return parts.join(separator).replace(replace, separator);
};

const debounce = (func, wait, immediate) => {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const scaleCanvas = (canvas, context, width, height) => {
	// assume the device pixel ratio is 1 if the browser doesn't specify it
	const devicePixelRatio = window.devicePixelRatio || 1;

	// determine the 'backing store ratio' of the canvas context
	const backingStoreRatio = window.devicePixelRatio || 1;

	// determine the actual ratio we want to draw at
	const ratio = devicePixelRatio / backingStoreRatio;

	if (devicePixelRatio !== backingStoreRatio) {
		// set the 'real' canvas size to the higher width/height
		canvas.width = width * ratio;
		canvas.height = height * ratio;

		// ...then scale it back down with CSS
		canvas.style.width = width + "px";
		canvas.style.height = height + "px";
	} else {
		// this is a normal 1:1 device; just scale it simply
		canvas.width = width;
		canvas.height = height;
		canvas.style.width = "";
		canvas.style.height = "";
	}

	// scale the drawing context so everything will work at the higher ratio
	context.scale(ratio, ratio);
};

export {
	twoDigitFormat,
	getRootOffset,
	getRelativeOffset,
	bindEvents,
	clamp,
	formatServices,
	pathJoin,
	debounce,
	scaleCanvas,
};
