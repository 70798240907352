import React, { useEffect, useRef, useState } from "react";
import Vimeo from "@vimeo/player";
import classNames from "classnames";

import ScrollController from "../../../utils/controller-scroll";

import classes from "./styles.module.scss";
import { defaults } from "gsap";

const Asset: React.FC<{
	src: string;
	alt?: string;
	vimeoId?: string;
	width?: number;
	height?: number;
	params?: string;
	resizeVideo?: boolean;
	loop?: boolean;
	posterImage?: string;
	autoplay?: boolean;
	playing?: boolean;
	fullSizeMode?: 'regular' | 'full-width' | 'full-bleed'
}> = ({
	src,
	alt = "",
	vimeoId,
	width,
	height,
	params,
	resizeVideo = true,
	loop = true,
	posterImage,
	autoplay = true,
	playing = true,
	fullSizeMode = 'regular'
}) => {
	const videoDimensions = [0, 0];
	const containerRef = useRef(null);
	const iframeRef = useRef(null);
	const [playerInstance, setPlayerInstance] = useState(null);
	const [videoIsVisible, setVideoIsVisible] = useState(true);

	let urlParams = "?fm=auto&q=80&lossless=true";

	if (params) {
		urlParams = `${urlParams}&${params}`;
	}

	let fullSizeClassName;

	switch (fullSizeMode) {
		case "full-bleed":
			fullSizeClassName = classes.fullBleed;
			break;
		case "full-width":
			fullSizeClassName = classes.fullWidth;
			break;
		default:
			fullSizeClassName = null;
			break;
	}

	let srcSet = "";
	let defaultSrc = "";
	let baseSrc = `${srcSet}${src}${urlParams}`;

	if (width) {
		let i = 100;

		while (i + 100 < width) {
			const ratio = height ? height / width : 0;
			srcSet = `${srcSet}${baseSrc}&w=${i}${
				height ? `&h=${i * ratio}` : ""
			} ${i}w ,`;

			if (i === 100) {
				defaultSrc = `${baseSrc}&w=${i}${height ? `&h=${i * ratio}` : ""}`;
			}
			i += 100;
		}

		srcSet = `${srcSet}${baseSrc}&w=${width}${
			height ? `&h=${height}` : ""
		} ${width}w, `;

		srcSet = `${srcSet}${baseSrc}&w=${parseInt(width * 1.5)}${
			height ? `&h=${parseInt(height * 1.5)}` : ""
		} 2x, `;

		srcSet = `${srcSet}${baseSrc}&w=${width * 2}${
			height ? `&h=${height * 2}` : ""
		} 2x`;
	} else {
		srcSet = baseSrc;
	}

	const onImageLoad = () => {
		requestAnimationFrame(() => {
			ScrollController.resize();
		});
	};

	const backgroundCover = (elementSizes, containerSizes, element) => {
		const elementRatio = elementSizes.width / elementSizes.height;
		const containerRatio = containerSizes.width / containerSizes.height;
		const renderedSize = [element.offsetWidth, element.offsetHeight];

		let width = null,
			height = null,
			marginTop = null,
			marginLeft = null;

		if (containerRatio > elementRatio) {
			width = Math.ceil(containerSizes.width);
			height = Math.ceil(containerSizes.width / elementRatio);
			marginTop = (containerSizes.height - renderedSize[1]) / 2;
			marginLeft = 0;
		} else {
			width = Math.ceil(containerSizes.height * elementRatio);
			height = Math.ceil(containerSizes.height);
			marginTop = 0;
			marginLeft = (containerSizes.width - renderedSize[0]) / 2;
		}

		return { width, height, marginTop, marginLeft };
	};

	const onResize = () => {
		if (!resizeVideo) {
			iframeRef.current.style.width = "";
			iframeRef.current.style.height = "";
		}

		const ratio = videoDimensions[1] / videoDimensions[0];
		const renderedWidth = iframeRef.current.offsetWidth;

		iframeRef.current.width = videoDimensions[1];
		iframeRef.current.height = videoDimensions[0];

		if (resizeVideo) {
			containerRef.current.style.width = "";
			containerRef.current.style.height = "";
			containerRef.current.style.marginLeft = "";
			containerRef.current.style.marginTop = "";

			const parent = iframeRef.current.offsetParent;
			const { offsetWidth, offsetHeight } = parent;

			const newSizes = backgroundCover(
				{ width: videoDimensions[0], height: videoDimensions[1] },
				{ width: offsetWidth, height: offsetHeight },
				iframeRef.current
			);

			containerRef.current.style.width = `${newSizes.width}px`;
			containerRef.current.style.height = `${newSizes.height}px`;
			containerRef.current.style.marginLeft = `${newSizes.marginLeft}px`;
			containerRef.current.style.marginTop = `${newSizes.marginTop}px`;

			iframeRef.current.style.width = `${newSizes.width}px`;
			iframeRef.current.style.height = `${newSizes.height}px`;
		} else {
			iframeRef.current.style.width = `${renderedWidth}px`;
			iframeRef.current.style.height = `${renderedWidth * ratio}px`;
		}
	};

	const onPlayerLoaded = player => {
		Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
			dimensions => {
				const [width, height] = dimensions;

				iframeRef.current.width = width;
				iframeRef.current.height = height;

				videoDimensions[0] = width;
				videoDimensions[1] = height;

				onResize();
			}
		);
	};

	useEffect(() => {
		if (iframeRef?.current) {
			const player = new Vimeo(iframeRef.current);

			setPlayerInstance(player);

			window.addEventListener("resize", onResize);

			player.on("loaded", () => {
				onPlayerLoaded(player);
			});

			player.on("progress", () => {
				onPlayerLoaded(player);
			});

			if (!loop) {
				const onTimeUpdate = e => {
					const { duration, seconds } = e;

					if (seconds >= duration - 2) {
						player.off("timeupdate");
						setVideoIsVisible(false);
					}
				};

				player.on("timeupdate", onTimeUpdate);
			}

			return () => {
				window.removeEventListener("resize", onResize);
				player.off("loaded");
				player.off("progress");
				player.destroy();
			};
		}
	}, []);

	useEffect(() => {
		if (!autoplay && playerInstance) {
			if (playing) {
				playerInstance.play();
			} else {
				playerInstance.pause();
			}
		}
	}, [playing]);

	return (
		<figure ref={containerRef} className={classes.container}>
			{vimeoId && (
				<iframe
					ref={iframeRef}
					className={classNames(
						classes.iframe,
						videoIsVisible ? "" : classes.isHidden
					)}
					src={`https://player.vimeo.com/video/${vimeoId}?background=1&autoplay=${autoplay}&muted=${autoplay}&loop=${
						loop ? "1" : "0"
					}`}
					title={`Vimeo video id ${vimeoId}`}
					frameBorder="0"
					width="640"
					height="360"
					allow="autoplay; fullscreen"
					loading="lazy"
					allowFullScreen
				></iframe>
			)}
			{!vimeoId && (
				<img
					srcSet={srcSet}
					src={defaultSrc}
					alt={alt}
					loading="lazy"
					decoding="async"
					draggable="false"
					onLoad={onImageLoad}
					width={width}
					height="auto"
					className={fullSizeClassName}
				/>
			)}
			{posterImage && (
				<img
					srcSet={srcSet}
					src={`${posterImage}${urlParams}`}
					alt={alt}
					loading="lazy"
					draggable="false"
					onLoad={onImageLoad}
					className={classNames(
						classes.posterImage,
						videoIsVisible ? classes.isHidden : classes.isVisible,
						fullSizeClassName
					)}
				/>
			)}
		</figure>
	);
};

export default Asset;
