import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import ScrollController from "../../../utils/controller-scroll";
import "swiper/swiper.scss";
import classes from "./styles.module.scss";

interface Attributes {
	image: string;
	alt: string;
	vimeoId: string;
}
interface GalleryAsset {
	attributes: Attributes;
}

const ImageGallery: React.FC<{ gallery_asset: GalleryAsset[] }> = ({
	gallery_asset,
}) => {
	const [swiperInstance, setSwiperInstance] = useState(null);
	const isTouch =
		swiperInstance &&
		(swiperInstance.device.ios || swiperInstance.device.android)
			? true
			: false;

	return (
		<div className={classes.container}>
			<div
				className={classes.images}
				data-cursor-layer="main"
				data-cursor-face="drag"
				data-cursor-hitbox=""
				data-cursor-disabled="false"
			>
				<Swiper
					className={classes.swiperContainer}
					freeMode={true}
					slidesPerView="auto"
					touchStartPreventDefault={!!isTouch}
					onSwiper={setSwiperInstance}
				>
					{gallery_asset.map((item, i) => (
						<SwiperSlide key={i} className={classes.image}>
							<img
								src={item.attributes.image}
								alt={item.attributes.alt}
								onLoad={() => {
									requestAnimationFrame(() => {
										ScrollController.resize();
									});
								}}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default ImageGallery;
