import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import InsightsLandingHero from "../components/heroes/insights-landing";
import InsightsLandingGrid from "../components/grids/insights-landing";

const InsightsPage: React.FC = ({ pageContext, path }) => {
	const { data, insightsDetails, type } = pageContext;
	const { seo, insights_details, prefooter } = data;
	const allInsights = insightsDetails
		? Array.from(insightsDetails)
		: Array.from(insights_details);
	let featuredInsights = [];
	const seoTitle = type ? `${type} • ${seo?.title}` : seo?.title;

	if (path === "/insights/") {
		const insightsFound = [];

		allInsights.map((item, i) => {
			if (insightsFound.indexOf(item.type) === -1) {
				insightsFound.push(item.type);
				featuredInsights.push(item);
				allInsights.splice(i, 1);
			}
		});
	} else {
		featuredInsights = allInsights.splice(0, 3);
	}

	return (
		<>
			<SEO
				title={seoTitle}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="transparent" prefooter={prefooter}>
				<InsightsLandingHero
					heading={data.title}
					featuredInsights={featuredInsights}
				/>
				<InsightsLandingGrid insights={allInsights} />
			</Layout>
		</>
	);
};

export default InsightsPage;
