import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
import HeroText from "../../text-blocks/hero-text";

const ServiceCategoryHero: React.FC<{
	heading: string;
	eyebrow: string;
	body: string;
	image1: string;
	image1Alt: string;
	image2: string;
	image2Alt: string;
	image3: string;
	image3Alt: string;
}> = ({
	heading,
	eyebrow,
	body,
	image1,
	image1Alt,
	image2,
	image2Alt,
	image3,
	image3Alt,
}) => (
	<div className={classes.container}>
		<div className={classes.heading}>
			<HeroText eyebrow="Services" heading={heading} bodyText={parse(body)} />
		</div>
		<div className={classes.mediaWrapper}>
			<Asset width={303} height={405} src={image1} alt={image1Alt} />
			<Asset width={305} height={591} src={image2} alt={image2Alt} />
			<Asset width={447} height={407} src={image3} alt={image3Alt} />
		</div>
	</div>
);

export default ServiceCategoryHero;
