import React from "react";
import { useStaticQuery, graphql, withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../header";
import PreFooter from "../pre-footer";
import Footer from "../footer";
import VirtualScroll from "../virtual-scroll";
import { PrefooterInterface } from "../pre-footer";

import CursorTakeover from "../takeover-cursor";

import "./styles.css";

const Layout: React.FC<{
	theme?: String;
	footer?: Boolean;
	prefooter?: PrefooterInterface;
}> = ({ children, location, theme = "light", footer = true, prefooter }) => {
	const data = useStaticQuery(graphql`
		{
			cms {
				navbar {
					items {
						label
						url
						description
					}
				}
				footer {
					name
					active
					signup_eyebrow
					links {
						name
						url
					}
					social {
						name
						url
					}
					contactSections {
						eyebrow
						line1
						line2
					}
				}
			}
		}
	`);

	const [footerData] = data.cms.footer;

	return (
		<>
			<Helmet>
				<script
					src={withPrefix("scripts/static.js")}
					type="text/javascript"
					async={true}
				/>

				<link
					rel="stylesheet"
					type="text/css"
					href="https://cloud.typography.com/7927898/6110612/css/fonts.css"
				/>

				<script type="application/ld+json">
					{`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "image": "/static/images/mj.png",
            "logo": "/static/images/mj.png",
            "@id": "https://metajive.com",
            "name": "Metajive",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "543 Encinitas Blvd #121",
              "addressLocality": "Encinitas",
              "addressRegion": "CA",
              "postalCode": "92024",
              "addressCountry": "US"
            },
            "url": "https://www.metajive.com/",
            "email": "hello@metajive.com",
            "telephone": "+17602746882",
            "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "09:00",
                "closes": "17:00"
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Saturday", "Sunday"],
                "opens": "00:00",
                "closes": "00:00"
              }],
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 33.046265,
              "longitude": -117.280625
            }
          }`}
				</script>
			</Helmet>

			<Header
				pathname={location?.pathname}
				theme={theme}
				items={data.cms.navbar[0]?.items}
				socialItems={footerData.social}
			/>

			<VirtualScroll>
				<main>{children}</main>
				{prefooter && (
					<PreFooter
						eyebrow={prefooter.eyebrow}
						sub_headline={prefooter.sub_headline}
						button_copy={prefooter.button_copy}
						button_link={prefooter.button_link}
						body_copy={prefooter.body_copy}
						show_newsletter_signup={prefooter.show_newsletter_signup}
					/>
				)}
				<Footer
					showMain={footer}
					links={footerData.links}
					socialLinks={footerData.social}
					newsletterEyebrow={footerData.signup_eyebrow}
					sayHelloEyebrow={footerData.contactSections[0].eyebrow}
					sayHelloLine1={footerData.contactSections[0].line1}
					sayHelloLine2={footerData.contactSections[0].line2}
					addressEyebrow={footerData.contactSections[1].eyebrow}
					addressLine1={footerData.contactSections[1].line1}
					addressLine2={footerData.contactSections[1].line2}
				/>
			</VirtualScroll>

			<CursorTakeover />
		</>
	);
};

export default Layout;
