import React, { useEffect, useState, useRef } from "react";
import slugify from "slugify";
import classNames from "classnames";
import { Link } from "gatsby";
import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import classes from "./styles.module.scss";

import { getRootOffset, pathJoin } from "../../utils";

import ParallaxImage from "../ui/parallax-image";

let currentSection = 0;
let lastSection = 0;
let sections = [];
let sectionBackgrounds = [];
let scrollMagicController = null;
interface PreviewContent {
	alt: string | null;
	eyebrow: string;
	feature_in_work_landing_page: boolean;
	headline: string;
	image: string;
	vimeoId: string | null;
}

interface Category {
	name: string;
	url: string;
}
interface ProjectDetailService {
	name: string;
	url: string;
	category: Category;
}

interface ProjectDetails {
	client_logo: string;
	project_title: string;
	project_url: string;
	casestudyprojectdetailservices: ProjectDetailService[];
}
interface Project {
	name: string;
	url: string;
	show_as_project: boolean;
	caseStudyPreviewContent: PreviewContent;
	caseStudyProjectDetail: ProjectDetails;
}

const WorkLandingTakeover: React.FC<{ projects: Project[] }> = ({
	projects,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const [activeSectionId, setActiveSectionID] = useState(currentSection);
	const wrapper = useRef(null);

	const measureSections = () => {
		sections.forEach(section => {
			const offset = getRootOffset(section.el);
			offset.bottom = offset.top + section.el.offsetHeight;

			section.offset = offset;
		});

		sectionBackgrounds.forEach(section => {
			const offset = getRootOffset(section.el);
			offset.bottom = offset.top + section.el.offsetHeight;

			section.offset = offset;
		});
	};

	const gatherSections = () => {
		sections = [];
		sectionBackgrounds = [];

		const sectionsFound = wrapper.current.querySelectorAll(
			`.${classes.section}`
		);

		const sectionBackgroundsFound = wrapper.current.parentNode.querySelectorAll(
			`.${classes.sectionBackground}`
		);

		sectionsFound.forEach((el, i) => {
			sections.push({
				id: i,
				el,
			});
		});

		sectionBackgroundsFound.forEach((el, i) => {
			sectionBackgrounds.push({
				id: i,
				el,
			});
		});
	};

	const createTweens = ScrollMagic => {
		const winHeight = window.innerHeight;

		for (let i = 0; i < sectionBackgrounds.length; i++) {
			const section = sectionBackgrounds[i];
			const sectionOffset = window.innerHeight * i;

			const tween = TweenMax.fromTo(
				section.el,
				1,
				{
					opacity: 0,
				},
				{
					opacity: 1,
				}
			);

			const scene = new ScrollMagic.Scene({
				duration: winHeight,
				offset: sectionOffset - winHeight / 4,
			})
				.setTween(tween)
				.addTo(scrollMagicController);
		}
	};

	const handleResize = () => {
		measureSections();
	};

	useEffect(() => {
		setIsVisible(true);
		gatherSections();
		measureSections();

		ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
		scrollMagicController = new ScrollMagic.Controller();
		createTweens(ScrollMagic);

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);

			if (scrollMagicController) {
				scrollMagicController.destroy();
				scrollMagicController = null;
			}
		};
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div ref={wrapper} className={classes.sections}>
				{projects.map((item, i) => {
					if (
						item.show_as_project ||
						!item.caseStudyPreviewContent.feature_in_work_landing_page
					) {
						return false;
					}

					return (
						<div key={i} className={classes.section}>
							<div
								className={classes.textWrapper}
								data-cursor-layer="main"
								data-cursor-face="dot"
								data-cursor-hitbox=""
								data-cursor-disabled="false"
							>
								<p className="eyebrow">
									{item.caseStudyPreviewContent.eyebrow}
								</p>
								<p className="h1">
									<Link
										to={pathJoin(["/work", item.url])}
										className={classes.cta}
									>
										/ {item.caseStudyPreviewContent.headline || item.name}
									</Link>
								</p>
								<ul className="metadata-large heading">
									{item.caseStudyProjectDetail?.casestudyprojectdetailservices.map(
										(item, i) => (
											<li key={i}>{item.name}</li>
										)
									)}
								</ul>
							</div>
							<div className={classes.imageWrapper}>
								<ParallaxImage
									width={1211}
									height={648}
									src={item.caseStudyPreviewContent.image || item.caseStudyHero?.image}
									alt={item.caseStudyPreviewContent.alt || item.caseStudyHero?.alt}
									maxShift={1.75}
								/>
							</div>
						</div>
					);
				})}
			</div>
			<div className={classes.background}>
				<div className={classes.backgroundWrapper} data-sticky>
					{projects.map((item, i) => {
						if (
							item.show_as_project ||
							!item.caseStudyPreviewContent.feature_in_work_landing_page
						) {
							return false;
						}

						const backgroundClass = classNames(classes.sectionBackground, {
							[classes.sectionBackgroundActive]: i === activeSectionId,
							[classes.sectionBackgroundPrevious]: i === lastSection,
						});

						return (
							<div key={i} className={backgroundClass} style={{ zIndex: i }}>
								<img
									src={`${item.caseStudyPreviewContent.image}?blur=150&w=720&fm=auto`}
									alt={item.caseStudyPreviewContent.alt}
									loading="lazy"
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default WorkLandingTakeover;
