import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import Blockquote from "../blockquote";
import CTAPlay from "../../ui/ctas/play";
import ImageUrl from "../../../../static/images/fpo/insights-detail-hero.jpg";

const InsightsDetailTextBlock: React.FC<{
	heading?: string;
	sub_heading?: string;
	body_copy?: string;
}> = ({ heading, sub_heading, body_copy }) => {
	
	const formatted = body_copy
		? body_copy
				.replace(/&lt;/g, "<")
				.replace(/&gt;/g, ">")
				.replace(/<br>/g, "")
				.replace(/&nbsp;/g, " ")
		: null;

	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				{heading && <p className="h2">{heading}</p>}
				{sub_heading && <p className="h4">{sub_heading}</p>}
			</div>
			{body_copy && (
				<div className={classes.bodyText}>
					{parse(formatted, {
						trim: true,
					})}
				</div>
			)}
		</div>
	);
};

export default InsightsDetailTextBlock;
