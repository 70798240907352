import React from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import classes from "./styles.module.scss";

const CareerDetailExcerpt: React.FC<{ heading: string; body: string }> = ({
	heading,
	body,
}) => (
	<div className={classNames(classes.container, "body-large")}>
		<h2>{heading}</h2>
		<div className={classes.bodyText}>{parse(body)}</div>
	</div>
);

export default CareerDetailExcerpt;
