import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO: React.FC<{
	title?: string;
	description?: string;
	image?: string;
	article?: boolean;
}> = ({ title, description, image, article }) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);

	let formattedDesc = description?.replace(/(<([^>]+)>)/gi, "");

	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		siteUrl,
		defaultImage,
		twitterUsername,
	} = site.siteMetadata;

	const seo = {
		title: title || defaultTitle,
		description: formattedDesc || defaultDescription,
		image: `${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	};

	return (
		<Helmet title={seo.title} titleTemplate={titleTemplate}>
			<html lang="en" />

			<meta property="og:locale" content="en_US" />

			<meta name="description" content={seo.description} />
			<meta name="image" content={`${seo.image}?w=1200&h=627&fit=crop`} />

			<meta property="og:site_name" content="Metajive" />

			{seo.url && <meta property="og:url" content={seo.url} />}

			<meta property="og:type" content={article ? "article" : "website"} />

			{seo.title && <meta property="og:title" content={seo.title} />}

			{formattedDesc && (
				<meta property="og:description" content={formattedDesc} />
			)}

			{seo.image && (
				<meta
					property="og:image"
					content={`${seo.image}?w=1200&h=627&fit=crop`}
				/>
			)}

			<meta name="twitter:url" content={seo.url} />
			<meta name="twitter:card" content="summary_large_image" />

			{twitterUsername && (
				<meta name="twitter:creator" content={twitterUsername} />
			)}

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{formattedDesc && (
				<meta name="twitter:description" content={formattedDesc} />
			)}

			{seo.image && (
				<meta
					name="twitter:image"
					content={`${seo.image}?w=1200&h=627&fit=crop`}
				/>
			)}
		</Helmet>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
};

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
				defaultImage: image
				twitterUsername
			}
		}
	}
`;
