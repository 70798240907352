import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ServiceCategoryHero from "../components/heroes/service-category";
import ServiceCategoryOverview from "../components/overviews/service-category";
import RelatedContent from "../components/related-content";
import Testimonial from "../components/testimonials/testimonial";

const ServiceCategoryPage: React.FC = ({ pageContext }) => {
	const { data } = pageContext;
	const {
		url,
		seo,
		prefooter,
		categoryhero,
		categoryoverview,
		services_category_details,
		categoryRelatedProjects,
		categorytestimonials,
	} = data;

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={true}
			/>
			<Layout prefooter={prefooter}>
				<ServiceCategoryHero
					heading={categoryhero.headline}
					eyebrow={categoryhero.eyebrow}
					body={categoryhero.body_copy}
					image1={categoryhero.asset.image[0]?.image}
					image1Alt={categoryhero.asset.image[0]?.alt}
					image2={categoryhero.asset.image[1]?.image}
					image2Alt={categoryhero.asset.image[1]?.alt}
					image3={categoryhero.asset.image[2]?.image}
					image3Alt={categoryhero.asset.image[2]?.alt}
				/>
				<ServiceCategoryOverview
					heading={categoryoverview.headline}
					body={categoryoverview.body_copy}
					services={services_category_details}
					baseUrl={url}
				/>
				<RelatedContent
					eyebrow={categoryRelatedProjects.eyebrow}
					heading={categoryRelatedProjects.headline}
					relatedItems={categoryRelatedProjects.categoryRelatedProjects}
					urlBase="/work"
				/>
				<Testimonial
					theme="light"
					testimonials={categorytestimonials}
					flushTop={false}
				/>
			</Layout>
		</>
	);
};

export default ServiceCategoryPage;
