import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";

import classes from "./styles.module.scss";

import LogoIcon from "../../assets/vectors/logo-icon.svg";
import Close from "../../assets/vectors/icons/x.svg";

import { NavItem } from "../header";

const MobileNav: React.FC<{
	active: Boolean;
	theme: String;
	toggleActive: Function;
	items: NavItem[];
	socialItems: NavItem[];
	eyebrow: string;
	phoneNumber: string;
	emailAddress: string;
}> = ({
	active,
	theme,
	toggleActive,
	items,
	socialItems,
	eyebrow,
	phoneNumber,
	emailAddress,
}) => {
	const data = useStaticQuery(graphql`
		{
			cms {
				footer {
					name
					active
					signup_eyebrow
					links {
						name
						url
					}
					social {
						name
						url
					}
					contactSections {
						eyebrow
						line1
						line2
					}
				}
			}
		}
	`);

	const [footer] = data.cms.footer;
	const contactInfo = footer.contactSections[0];

	const containerClass = classNames(classes.container, {
		[classes.isActive]: active,
	});

	const hideNav = () => {
		toggleActive(false);
	};

	return (
		<div className={containerClass}>
			<div className={classes.heading}>
				<div className={classes.logo}>
					<Link to="/" onClick={hideNav}>
						<LogoIcon />
					</Link>
				</div>
				<button className={classes.close} onClick={hideNav}>
					<figure>
						<Close />
					</figure>
				</button>
			</div>
			<div className={classes.items}>
				<ul className={classes.links}>
					{items &&
						items.map((item, i) => (
							<li key={i}>
								<Link to={item.url} onClick={hideNav} className="h1">
									<span>{item.label}</span>
								</Link>
							</li>
						))}
				</ul>
				<div className={classes.sayHello}>
					<span className="eyebrow">{contactInfo.eyebrow}</span>
					<div>
						<a href={`mailto:${contactInfo.line1}`} className="h4">
							{contactInfo.line1}
						</a>
						<br />
						<a href={`tel:${contactInfo.line2}`} className="h4">
							{contactInfo.line2}
						</a>
					</div>
				</div>
				<ul className={classNames(classes.social, "metadata")}>
					{socialItems.map((item, i) => {
						return (
							<li key={i}>
								<a href={item.url} target="_blank" rel="noreferrer">
									{item.name}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default MobileNav;
