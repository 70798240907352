import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";

import classes from "./styles.module.scss";

import CTAUnderline from "../../ui/ctas/underline";

interface ServiceDetail {
	name: string;
	url: string;
	servicehero: {
		body_copy: string;
	};
}

const ServiceCategoryOverview: React.FC<{
	heading: string;
	body: string;
	services: ServiceDetail[];
	baseUrl: string;
}> = ({ heading, body, services, baseUrl }) => (
	<div className={classes.container}>
		<div className={classes.heading}>
			<h2>{heading}</h2>
			<div className={classes.bodyText}>{parse(body)}</div>
		</div>
		<div className={classes.listWrapper}>
			<div className={classes.listHeading}>
				<p>Design Solutions</p>
			</div>
			<dl>
				{services.map((service, i) => {
					return (
						<div key={i}>
							<div className={classes.listItemWrapper}>
								<dt className="h5">{service.name}</dt>
								<dd>{parse(service.servicehero.body_copy)}</dd>
								<dd>
									<CTAUnderline
										label="Read More"
										url={`/services${baseUrl}${service.url}`}
									/>
								</dd>
							</div>
						</div>
					);
				})}
			</dl>
		</div>
	</div>
);

export default ServiceCategoryOverview;
