import React, { FC } from "react";
import slugify from "slugify";
import { Link } from "gatsby";
import { Insight } from "../../../../interfaces/common";
import { pathJoin } from "../../../utils";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
// import CTARound from "../../ui/ctas/round";
// import IconPlus from "../../../assets/vectors/icons/plus.svg";

const Item: FC<Insight> = props => {
	const {
		active,
		seo,
		type,
		url,
		name,
		insightsDetailPageHero,
		...rest
	} = props;

	return (
		<div className={classes.item} {...rest}>
			<div className={classes.itemImage}>
				<Asset
					width={362}
					height={258}
					params="fit=crop"
					src={insightsDetailPageHero.image}
					alt={insightsDetailPageHero.alt}
				/>
			</div>
			<div className={classes.itemText}>
				<p className="metadata">{type}</p>
				<Link to={pathJoin(["/insights", slugify(type.toLowerCase()), url])}>
					{name}
				</Link>
			</div>
		</div>
	);
};

const InsightsLandingGrid: React.FC<{ insights: Insight[] }> = ({
	insights,
}) => {
	return (
		<div className={classes.container}>
			<div className={classes.items}>
				<div className={classes.columnLeft}>
					{insights.map((item, i) => (
						<Item
							key={i}
							url={item.url}
							name={item.name}
							type={item.type}
							insightsDetailPageHero={item.insightsDetailPageHero}
						/>
					))}
				</div>
				<div className={classes.columnRight}>
					{insights.map((item, i) => {
						if (i % 2 === 1) {
							return (
								<Item
									key={i}
									url={item.url}
									name={item.name}
									type={item.type}
									insightsDetailPageHero={item.insightsDetailPageHero}
								/>
							);
						}

						return null;
					})}
				</div>
			</div>
			{/* <div className={classes.cta}>
				<CTARound
					url="/insights/page/2/"
					label="Load More"
					icon={<IconPlus />}
				/>
			</div> */}
		</div>
	);
};

export default InsightsLandingGrid;
