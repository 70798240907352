import React, { useEffect, useRef } from "react"

import HeightController from "../../utils/controller-height"
import ScrollController from "../../utils/controller-scroll"

import classes from "./styles.module.scss"

const VirtualScroll: React.FC<{}> = ({ children }) => {
	const content = useRef(null)
	const spacer = useRef(null)

	const onResize = () => {
		ScrollController.resize()
	}

	const onTouchStart = () => {
		document.documentElement.classList.add("is-touch")
		document.removeEventListener("touchstart", onTouchStart)
		ScrollController.rendering = false
		ScrollController.ease.duration = 20
		ScrollController.removeStyles()
	}

	const onUpdate = () => {}

	const onRender = () => {}

	useEffect(() => {
		HeightController.initialize(spacer.current)
		ScrollController.initialize(content.current, ["div"])

		window.addEventListener("resize", onResize)
		document.addEventListener("touchstart", onTouchStart)

		onResize()

		ScrollController.refresh(true)

		return () => {
			window.removeEventListener("resize", onResize)
		}
	}, [])

	return (
		<>
			<div ref={content} className={classes.content}>
				{children}
			</div>
			<div ref={spacer} className={classes.spacer}></div>
		</>
	)
}

export default VirtualScroll
