import React from 'react'

import classes from './styles.module.scss'

const PhotoGrid:React.FC<{}> = ({ children }) => (
    <div className={ classes.container }>
        { children }
    </div>
)

export default PhotoGrid
