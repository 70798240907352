import React from "react";
import Helmet from "react-helmet";

import SEO from "../components/seo";
import Layout from "../components/layout";
import ModulesFromFields from "../utils/modules-from-fields";

import HeroCaseStudy from "../components/heroes/detail-pages/case-study";
import CaseStudyIntro from "../components/text-blocks/case-study-intro";
import RelatedContent from "../components/related-content";

const CaseStudyLiteDetail: React.FC = ({ pageContext }) => {
	const { data, noIndex } = pageContext;

	const {
		seo,
		caseStudyRelatedProjects,
		caseStudyHero,
		caseStudyProjectDetail,
		caseStudyLiteProjectIntro,
	} = data;

	const Modules = ModulesFromFields(data.fields);

	return (
		<>
			<Helmet>
				<meta name="twitter:label1" value="Project Name" />
				<meta
					name="twitter:data1"
					value={caseStudyProjectDetail.project_title}
				/>
				{noIndex && <meta name="robots" content="noindex" />}
				<meta name="twitter:label2" value="Project Url" />
				<meta name="twitter:data2" value={caseStudyProjectDetail.project_url} />
			</Helmet>

			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="transparent">
				<HeroCaseStudy
					heading={caseStudyHero.headline}
					heroImage={caseStudyHero.image}
					vimeoId={caseStudyHero.vimeoId}
					projectTitle={caseStudyProjectDetail.project_title}
					projectUrl={caseStudyProjectDetail.project_url}
					services={caseStudyProjectDetail.casestudyprojectdetailservices}
					logo={caseStudyProjectDetail.client_logo}
					backgroundColor={caseStudyLiteProjectIntro.hexCode}
				/>

				<CaseStudyIntro
					introText={caseStudyLiteProjectIntro.power_statement}
					backgroundColor={caseStudyLiteProjectIntro.hexCode}
					logo={caseStudyLiteProjectIntro.client_logo}
					image={caseStudyLiteProjectIntro.image}
					imageAlt={caseStudyLiteProjectIntro.image_alt}
					flushBottom={data.fields && data.fields.length > 0}
				/>

				<div className="sections module-section">
					<>{Modules}</>
					{caseStudyRelatedProjects && (
						<RelatedContent
							eyebrow={caseStudyRelatedProjects.eyebrow}
							heading={caseStudyRelatedProjects.headline}
							relatedItems={caseStudyRelatedProjects.caseStudyRelatedProjects}
							urlBase="/work"
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default CaseStudyLiteDetail;
