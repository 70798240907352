import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import classes from "./styles.module.scss";

const WorkLandingHero: React.FC<{ heading: string; subHeading: string }> = ({
	heading,
	subHeading,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div className={classes.headingWrapper}>
				<h1>{heading}</h1>
			</div>
			<div className={classNames(classes.bodyText, "metadata-large")}>
				{subHeading && parse(subHeading)}
			</div>
		</div>
	);
};

export default WorkLandingHero;
