import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Swiper from "@types/swiper";

import { twoDigitFormat } from "../../../utils";

import classes from "./styles.module.scss";

const CarouselNavigation: React.FC<{
	theme: String;
	swiperInstance: Swiper;
	numSlides?: number;
}> = ({ theme = "default", swiperInstance, numSlides }) => {
	if (!swiperInstance) return false;

	const themes = {
		default: "",
		light: classes.themeLight,
	};

	const [slideNum, setSlideNum] = useState(swiperInstance.realIndex);
	const slideCount = numSlides || swiperInstance.slides.length;
	const progress = (slideNum + 1) / slideCount;

	const prevSlide = () => {
		swiperInstance.slidePrev();
		setSlideNum(swiperInstance.realIndex);

		setTimeout(() => {
			window.dispatchEvent(new Event("scroll"));
		}, 100);
	};

	const nextSlide = () => {
		swiperInstance.slideNext();
		setSlideNum(swiperInstance.realIndex);

		setTimeout(() => {
			window.dispatchEvent(new Event("scroll"));
		}, 100);
	};

	useEffect(() => {
		swiperInstance.on("slideChange", () => {
			setSlideNum(swiperInstance.realIndex);
		});
	}, []);

	return (
		<div className={classNames(classes.container, themes[theme])}>
			<button className="metadata" onClick={prevSlide}>
				{twoDigitFormat(slideNum + 1)}
			</button>
			<button className="metadata" onClick={nextSlide}>
				{twoDigitFormat(slideCount)}
			</button>
			<div className={classes.progress}>
				<span style={{ transform: `scaleX(${progress})` }}></span>
			</div>
		</div>
	);
};

export default CarouselNavigation;
