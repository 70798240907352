import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import classes from "./styles.module.scss";
import Asset from "../ui/asset";

interface SocialLink {
	url: string;
	name: string;
}

const ContactModule: React.FC<{
	section1Eyebrow: string;
	section1Email: string;
	section1Phone: string;
	section2Eyebrow: string;
	section2Line1: string;
	section2Line2: string;
	section3Eyebrow: string;
	section3Email: string;
	section3Phone: string;
	section4Eyebrow: string;
	section4Email: string;
	section4Phone: string;
	careersImage: string;
	careersImageAlt: string;
	careersCTA: string;
	careersCTAUrl: string;
	social: SocialLink[];
}> = ({
	section1Eyebrow,
	section1Email,
	section1Phone,
	section2Eyebrow,
	section2Line1,
	section2Line2,
	section3Eyebrow,
	section3Email,
	section3Phone,
	section4Eyebrow,
	section4Email,
	section4Phone,
	careersImage,
	careersImageAlt,
	careersCTA,
	careersCTAUrl,
	social,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div className={classes.textWrapper}>
				<div className={classes.textGroup}>
					<span className="metadata">{section1Eyebrow}</span>
					<a href={`mailto:${section1Email}`}>{section1Email}</a>
					<br />
					<a href={`tel:${section1Email}`}>{section1Phone}</a>
				</div>
				<div className={classes.textGroup}>
					<span className={classNames("metadata", classes.eyebrow)}>
						{section2Eyebrow}
					</span>
					<p>
						{section2Line1}
						<br />
						{section2Line2}
					</p>
				</div>
				<div className={classNames(classes.textGroup, classes.textGroupFlex)}>
					<div className={classes.textSubGroup}>
						<span className={classNames("metadata", classes.eyebrow)}>
							{section3Eyebrow}
						</span>
						{section3Phone && section3Phone !== '' && (
							<>
								<a href={`tel:${section3Phone}`}>{section3Phone}</a>
								<br />
							</>
						)}
						<a href={`mailto:${section3Email}`}>{section3Email}</a>
					</div>
					<div className={classes.textSubGroup}>
						<span className={classNames("metadata", classes.eyebrow)}>
							{section4Eyebrow}
						</span>
						{section4Phone && section4Phone !== '' && (
							<>
								<a href={`tel:${section4Phone}`}>{section4Phone}</a>
								<br />
							</>
						)}
						<a href={`mailto:${section4Email}`}>{section4Email}</a>
					</div>
				</div>
				<div className={classes.textGroup}>
					<ul className={classNames(classes.social, "metadata")}>
						{social.map((link, i) => (
							<li key={i}>
								<a href={link.url} target="_blank">
									{link.name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className={classes.mediaWrapper}>
				<Asset
					width={720}
					height={579}
					src={careersImage}
					alt={careersImageAlt}
				/>
				<div className={classes.cta}>
					<p>Join Our Team</p>
					<Link to={careersCTAUrl}>{careersCTA}</Link>
				</div>
			</div>
		</div>
	);
};

export default ContactModule;
