import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";

import classes from "./styles.module.scss";

import DetailPageHero from "../index";
import { pathJoin } from "../../../../utils";

interface Service {
	name: string;
	url: string;
	category: {
		name: string;
		url: string;
	};
}

const CaseStudyHero: React.FC<{
	heading: string;
	heroImage: string;
	vimeoId?: string;
	projectTitle: string;
	projectUrl: string;
	services: Service[];
	logo: string;
	backgroundColor: string;
}> = ({
	heading,
	heroImage,
	vimeoId,
	projectTitle,
	projectUrl,
	services,
	logo,
	backgroundColor,
}) => (
	<DetailPageHero
		heading={heading}
		foregroundImage={heroImage}
		backgroundImage={heroImage}
		backgroundColor={backgroundColor}
		vimeoId={vimeoId}
	>
		<div className={classes.logoWrapper}>
			<img src={`${ process.env.GATSBY_CMS_URL || 'https://cms.metajive.com'}/storage/${logo}`} alt={heading} />
		</div>
		<div className={classes.details}>
			<div className={classNames(classes.detailsGroup, "eyebrow")}>
				<p className={classNames("metadata", classes.metadata)}>Project</p>
				<p className={classNames("h5", classes.subhead)}>{projectTitle}</p>
				<p>
					<a href={projectUrl} target="_blank" rel="noreferrer">
						{projectUrl}
					</a>
				</p>
			</div>
			<div className={classNames(classes.detailsGroup, "eyebrow")}>
				{services && services.length > 0 && (
					<>
						<p className={classNames("metadata", classes.metadata)}>Services</p>
						<ul>
							{services.map((item, i) => {
								return (
									<li key={i}>
										<Link
											to={pathJoin(["/services", item.category.url, item.url])}
										>
											{item.name}
										</Link>
									</li>
								);
							})}
						</ul>
					</>
				)}
			</div>
		</div>
	</DetailPageHero>
);

export default CaseStudyHero;
