import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import { twoDigitFormat } from "../../utils";
import Asset from "../ui/asset";
import StackedTitle from "../text-blocks/stacked-title";

import ImageUrl from "../../../static/images/fpo/values.jpg";

interface Value {
	image: string;
	alt: string;
	vimeoId: string;
	headline: string;
	eyebrow: string;
	caption: string;
}

const CompanyValues: React.FC<{
	heading: string;
	bodyText: string;
	values: Value[];
}> = ({ heading, bodyText, values }) => {
	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<StackedTitle heading={heading} bodyText={bodyText} />
				<p></p>
			</div>
			<div className={classes.values}>
				<ul>
					{values.map((item, i) => (
						<li key={i}>
							<div className={classes.valueImage}>
								<Asset
									width={350}
									height={350}
									src={item.image}
									alt={item.alt}
									vimeoId={item.vimeoId}
								/>
							</div>
							<div className={classes.valueText}>
								<span className="metadata-large">{item.eyebrow}</span>
								<p className="h2">{item.headline}</p>
								<div className="metadata-large">{parse(item.caption)}</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default CompanyValues;
