import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

const InsightsDetailExcerpt: React.FC<{
	eyebrow: string;
	heading: string;
	bodyText: string;
}> = ({ eyebrow, heading, bodyText }) => {
	return (
		<div className={classes.container}>
			<div className={classes.eyebrow}>
				<span className="eyebrow">{eyebrow}</span>
			</div>
			<h2 className={classes.heading}>{heading}</h2>
			{bodyText && <div className={classes.bodyText}>{parse(bodyText)}</div>}
		</div>
	);
};

export default InsightsDetailExcerpt;
