import React from "react";
import classNames from "classnames";

import Asset from "../../ui/asset";

import classes from "./styles.module.scss";

const CaseStudyIntro: React.FC<{
	introText: string;
	backgroundColor: string;
	logo: string;
	image: string;
	imageAlt: string;
	flushBottom?: boolean;
	vimeoId?: string;
}> = ({
	introText,
	backgroundColor,
	logo,
	image,
	imageAlt,
	flushBottom,
	vimeoId,
}) => {
	return (
		<div
			className={classNames(
				classes.container,
				image ? classes.withImages : classes.withoutImages,
				{
					[classes.flushBottom]: flushBottom,
				}
			)}
		>
			<div
				className={classNames(classes.bodyText, "body-large")}
				style={{ backgroundColor: backgroundColor || "var(--brand-black)" }}
			>
				<p>{introText}</p>
			</div>
			{image && (
				<div className={classes.images}>
					{!logo && image && (
						<Asset src={image} alt={imageAlt} vimeoId={vimeoId} />
					)}
					{logo && image && (
						<>
							<div className={classes.logoWrapper}>
								<Asset width={400} height={400} src={logo} alt={imageAlt} />
							</div>
							<div className={classes.imageWrapper}>
								<Asset
									src={image}
									alt={imageAlt}
									vimeoId={vimeoId}
									resizeVideo={false}
								/>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default CaseStudyIntro;
