import React from "react";

import { Link } from "gatsby";

import classes from "./stlyes.module.scss";

const CTAInlineDash: React.FC<{
	url: String;
	label: String;
	className?: String;
}> = props => {
	const { url, label, className = "", ...restProps } = props;
	return (
		<Link
			to={url}
			className={`${classes.container} ${className}`}
			{...restProps}
		>
			<span className={classes.icon}></span>
			<span className={classes.label}>{label}</span>
		</Link>
	);
};

export default CTAInlineDash;
