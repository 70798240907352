import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";

import ServicesLandingHero from "../components/heroes/services-landing";
import ServicesLandingCategory from "../components/services-landing-category";

export const query = graphql`
	query ServicesLandingPage {
		cms {
			services_landing_page {
				url
				#seo {
				#	title
				#	description
				#	thumbnail
				#}
				serviceslandinghero {
					eyebrow
					headline
					body_copy
					image1
					alt1
					image2
					alt2
					image3
					alt3
					image4
					alt4
				}
				serviceslandingcategories {
					body_copy
					cta_text
					cta_url
					category {
						name
						url
						services_category_details {
							name
							url
							servicehero {
								eyebrow
								headline
								body_copy
								image
								alt
								vimeoId
							}
						}
						categoryhero {
							asset {
								image {
									alt
									image
									vimeoId
								}
							}
						}
					}
				}
				prefooter {
					eyebrow
					sub_headline
					body_copy
					button_copy
					button_link
					show_newsletter_signup
				}
			}
		}
	}
`;

const ServicesPage: React.FC = ({ data }) => {
	const page = data.cms.services_landing_page[0];
	const {
		seo,
		serviceslandinghero,
		serviceslandingcategories,
		prefooter,
	} = page;
	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout prefooter={prefooter}>
				<ServicesLandingHero
					eyebrow={serviceslandinghero.eyebrow}
					heading={serviceslandinghero.headline}
					bodyText={serviceslandinghero.body_copy}
					image1={serviceslandinghero.image1}
					alt1={serviceslandinghero.alt1}
					image2={serviceslandinghero.image2}
					alt2={serviceslandinghero.alt2}
					image3={serviceslandinghero.image3}
					alt3={serviceslandinghero.alt3}
					image4={serviceslandinghero.image4}
					alt4={serviceslandinghero.alt4}
				/>
				<div className="services-landing-services">
					{serviceslandingcategories.map((category, i) => {
						return (
							<ServicesLandingCategory
								key={i}
								heading={category.category.name}
								body={category.body_copy}
								cta={category.cta_text}
								ctaUrl={`/services${category.cta_url}`}
								baseUrl={category.cta_url}
								subcategories={category.category.services_category_details}
								imageLayout={i % 3}
								image1={category.category.categoryhero.asset.image[0]?.image}
								vimeoId1={
									category.category.categoryhero.asset.image[0]?.vimeoId
								}
								image1Alt={category.category.categoryhero.asset.image[0]?.alt}
								image2={category.category.categoryhero.asset.image[1]?.image}
								vimeoId2={
									category.category.categoryhero.asset.image[1]?.vimeoId
								}
								image2Alt={category.category.categoryhero.asset.image[1]?.alt}
								image3={category.category.categoryhero.asset.image[2]?.image}
								vimeoId3={
									category.category.categoryhero.asset.image[2]?.vimeoId
								}
								image3Alt={category.category.categoryhero.asset.image[2]?.alt}
							/>
						);
					})}
				</div>
			</Layout>
		</>
	);
};

export default ServicesPage;
