import React, { useEffect, useRef } from "react";

import AnimationController from "../../../utils/controller-animation";
import ScrollController from "../../../utils/controller-scroll";
import { getRootOffset, clamp } from "../../../utils";

import classes from "./styles.module.scss";

import Asset from "../asset";

const ParallaxImage: React.FC<{
	fill?: boolean;
	width: number;
	height: number;
	src: string;
	alt: string;
	debug?: boolean;
	maxShift?: number;
	params?: string;
	vimeoId?: string;
}> = props => {
	const {
		width,
		height,
		src,
		alt,
		fill,
		debug,
		maxShift,
		params,
		vimeoId,
		...restProps
	} = props;

	const wrapper = useRef(null);

	let bounds = {
		top: 0,
		bottom: 0,
		height: 0,
	};

	const onResize = () => {
		bounds.top = getRootOffset(wrapper.current).top;
		bounds.height = wrapper.current.offsetHeight;
		bounds.bottom = bounds.top + bounds.height;
	};

	const onUpdate = () => {
		let render = false;

		if (
			ScrollController.eased + window.innerHeight >= bounds.top &&
			ScrollController.eased <= bounds.bottom
		) {
			render = true;
		}

		return render;
	};

	const onRender = () => {
		const transformOriginY = 0.7;
		const maxScale = maxShift ? maxShift : 1.3;
		const shift = bounds.height * (maxScale - 1);

		const centerY = bounds.top + bounds.height / 2;
		const centerRatio = clamp(
			(ScrollController.eased + (window.innerHeight / 4) * 3 - centerY) /
				window.innerHeight,
			-1,
			1
		);

		const shiftY = (centerRatio / 2) * shift * transformOriginY;

		wrapper.current.style.transform = `translate3d(0, ${shiftY}px, 0) scale3d(1.1, 1.1, 1)`;
	};

	useEffect(() => {
		const animation = AnimationController.set({
			update: onUpdate,
			render: onRender,
		});

		window.addEventListener("resize", onResize);
		onResize();

		return () => {
			AnimationController.clear(animation);
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return (
		<div className={classes.container}>
			<div ref={wrapper} className={classes.wrapper}>
				<Asset
					width={width}
					height={height}
					src={src}
					alt={alt}
					params={params}
					vimeoId={vimeoId}
					{...restProps}
				/>
			</div>
		</div>
	);
};

export default ParallaxImage;
