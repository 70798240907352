import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import slugify from "slugify";

import Asset from "../ui/asset";
import classes from "./styles.module.scss";
interface PreviewContent {
	image: string;
	eyebrow: string;
	alt: string;
	headline: string;
	feature_in_work_landing_page?: boolean;
}

interface InsightPreviewContent {
	image: string;
}
interface RelatedItem {
	active: boolean;
	url: string;
	caseStudyPreviewContent?: PreviewContent;
	insightsDetailPageHero?: InsightPreviewContent;
	type?: string;
	name?: string;
}

const RelatedCaseStudies: React.FC<{
	heading: String;
	eyebrow: String;
	relatedItems: RelatedItem[];
	urlBase: string;
}> = ({ heading, eyebrow, relatedItems, urlBase }) => {
	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<span className={classNames(classes.eyebrow, "eyebrow")}>
					{eyebrow}
				</span>
				<p className="h2">{heading}</p>
			</div>
			<div className={classes.caseStudies}>
				{relatedItems.map((item, i) => {
					return (
						<div key={i} className={classes.caseStudy}>
							<div className={classes.caseStudyText}>
								<span className="eyebrow">
									{item.caseStudyPreviewContent &&
										item.caseStudyPreviewContent.eyebrow}
									{!item.caseStudyPreviewContent && item.type && item.type}
								</span>
								<p className="h4">
									<Link
										to={`${urlBase}${
											item.type ? slugify(item.type.toLowerCase()) : ""
										}${item?.caseStudyPreviewContent?.feature_in_work_landing_page === false ? '/private' : ''}${item.url}`}
									>
										{item.caseStudyPreviewContent &&
											item.caseStudyPreviewContent.headline}
										{!item.caseStudyPreviewContent && item.name && item.name}
									</Link>
								</p>
							</div>
							<div className={classes.caseStudyMedia}>
								<Asset
									width={720}
									height={468}
									src={
										item.caseStudyPreviewContent
											? item.caseStudyPreviewContent.image
											: item.insightsDetailPageHero.image
									}
									alt={
										item.caseStudyPreviewContent
											? item.caseStudyPreviewContent.alt
											: item.insightsDetailPageHero.alt
									}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RelatedCaseStudies;
