import React, { useState } from "react";
import classNames from "classnames";
import ScrollController from "../../utils/controller-scroll";

import classes from "./styles.module.scss";

import Asset from "../ui/asset";

const InlineImageBlock: React.FC<{
	image: string;
	alt: string;
	align: string;
	horizontal: string;
	vimeoId?: string;
}> = ({ image, alt, horizontal = "left", vimeoId }) => {
	return (
		<div className={classNames(classes.container, classes[horizontal])}>
			<div className={classes.imageWrapper}>
				<Asset
					width={2560}
					src={image}
					alt={alt}
					vimeoId={vimeoId}
					resizeVideo={false}
				/>
			</div>
		</div>
	);
};

export default InlineImageBlock;
