import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import CareerDetailHero from "../components/heroes/detail-pages/career-detail";
import CareerDetailExcerpt from "../components/excerpts/career-detail";
import ModulesFromFields from "../utils/modules-from-fields";
import CareersListing from "../components/careers-listing";

const WorkPage: React.FC = ({ pageContext }) => {
	const { data } = pageContext;
	const {
		seo,
		prefooter,
		fields,
		careerDetailPageHero,
		careerDetailPageJobIntro,
		careerDetailPagePosition,
		careerDetailPageRelatedCareerOverview,
	} = data;

	const Modules = ModulesFromFields(fields);

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout prefooter={prefooter} theme="transparent">
				<CareerDetailHero
					heading={careerDetailPageHero.headline}
					image={careerDetailPageHero.image}
					imageAlt={careerDetailPageHero.headline}
					description={careerDetailPagePosition.description}
					date={careerDetailPagePosition.eyebrow}
					cta={careerDetailPagePosition.cta_text}
					ctaURL={careerDetailPagePosition.cta_link}
					positionTitle={careerDetailPagePosition.position_title}
				/>
				<CareerDetailExcerpt
					heading={careerDetailPageJobIntro.long_headline}
					body={careerDetailPageJobIntro.body_copy}
				/>
				<div className="sections">
					<>{Modules}</>
				</div>
				<CareersListing
					jobsTableHeading={careerDetailPageRelatedCareerOverview.headline}
					jobsTableEyebrow={careerDetailPageRelatedCareerOverview.eyebrow}
					jobPostings={careerDetailPageRelatedCareerOverview.related_carrers}
				/>
			</Layout>
		</>
	);
};

export default WorkPage;
