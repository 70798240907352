import React from "react";
import { graphql } from "gatsby";

import ScrollController from "../utils/controller-scroll";
import Layout from "../components/layout";
import SEO from "../components/seo";

import StandardHero from "../components/heroes/standard";
import CareersLandingExcerpt from "../components/excerpts/careers-landing";
import FullWidthMedia from "../components/full-width-media";
import Testimonial from "../components/testimonials/testimonial";
import CareersListing from "../components/careers-listing";

import ArrowDown from "../assets/vectors/arrow-down.svg";

export const query = graphql`
	query CareersLandingQuery {
		cms {
			career_landing_page {
				url
				seo {
					title
					description
					thumbnail
				}
				careerLandingPagehero {
					eyebrow
					headline
					image
					alt
					vimeoId
					cta_copy
					cta_link
				}
				careerLandingPageIntro {
					headline
					body_copy
					image
					alt
					vimeoId
					CareerLandingPageIntroSections {
						title
						body_copy
						logo
						alt
					}
				}
				careerLandingPageJobsOpening {
					headline
					body_copy
					image
					alt
					vimeoId
					active_jobs_table_eyebrow
					active_jobs_table_headline
				}
				prefooter {
					eyebrow
					sub_headline
					body_copy
					button_copy
					button_link
					show_newsletter_signup
				}
				careerLandingPageTestimonials {
					subheading
					heading
					bodytext
					logo {
						hexCode
						logo
					}
					media {
						image
						video
					}
				}
			}
			career_detail_pages {
				name
				url
				careerDetailPagePosition {
					description
					position_title
				}
			}
		}
	}
`;

const WorkPage: React.FC = ({ data }) => {
	const { career_landing_page, career_detail_pages } = data.cms;
	const {
		seo,
		prefooter,
		careerLandingPagehero,
		careerLandingPageIntro,
		careerLandingPageJobsOpening,
		careerLandingPageTestimonials,
	} = career_landing_page;

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout prefooter={prefooter} theme="transparent">
				<StandardHero
					eyebrow={careerLandingPagehero.eyebrow}
					heading={careerLandingPagehero.headline}
					image={careerLandingPagehero.image}
					imageAlt={careerLandingPagehero.alt}
					cta={careerLandingPagehero.cta_copy}
					ctaIcon={<ArrowDown />}
					ctaOnClick={() => {
						const target = document.querySelector(
							careerLandingPagehero.cta_link
						);

						if (target) {
							const { offsetTop } = target;
							ScrollController.scrollTo(offsetTop);
						}
					}}
				/>
				<CareersLandingExcerpt
					heading={careerLandingPageIntro.headline}
					body={careerLandingPageIntro.body_copy}
					listItems={careerLandingPageIntro.CareerLandingPageIntroSections}
				/>
				<FullWidthMedia
					flushBottom={true}
					padRight={true}
					splitBackground={true}
					image={careerLandingPageIntro.image}
					alt={careerLandingPageIntro.alt}
					vimeoId={careerLandingPageIntro.vimeoId}
				/>

				<Testimonial
					theme="light"
					flushTop={false}
					testimonials={careerLandingPageTestimonials}
				/>

				<CareersListing
					heading={careerLandingPageJobsOpening.headline}
					body={careerLandingPageJobsOpening.body_copy}
					image={careerLandingPageJobsOpening.image}
					alt={careerLandingPageJobsOpening.alt}
					jobsTableHeading={
						careerLandingPageJobsOpening.active_jobs_table_headline
					}
					jobsTableEyebrow={
						careerLandingPageJobsOpening.active_jobs_table_eyebrow
					}
					jobPostings={career_detail_pages}
				/>
			</Layout>
		</>
	);
};

export default WorkPage;
