import React from "react";
import parse from "html-react-parser";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";

import ScrollController from "../../../utils/controller-scroll";
import classes from "./styles.module.scss";
import QuoteMark from "../../../assets/vectors/quote.svg";
import Logo from "../../../assets/vectors/logos/school-of-rock-light.svg";

export enum TestimonialThemes {
	light = "light",
	dark = "dark",
}

interface Testimonial {
	heading: string;
	bodytext: string;
	logo: {
		hexCode: string;
		logo: string;
	};
	media: string;
	subheading: string;
}

const Testimonial: React.FC<{
	theme: string;
	flushTop?: boolean;
	testimonials: string | Testimonial[];
}> = ({ theme = "light", flushTop = true, testimonials }) => {
	console.log('Testimonial', testimonials)
	const themes = {
		light: classes.themeLight,
		dark: classes.themeDark,
	};

	let testimonialsFound = [];

	if (typeof testimonials == "string") {
		let parsed = JSON.parse(testimonials);
		parsed.forEach(testimonial => {
			testimonialsFound.push(testimonial);
		});
	} else {
		testimonials.forEach(testimonial => {
			testimonialsFound.push(testimonial.heading);
		});
	}

	const data = useStaticQuery(graphql`
		query TestimonialsQuery {
			cms {
				testimonials {
					heading
					logo {
						logo
						hexCode
					}
					media {
						image
						video
					}
					subheading
					bodytext
				}
			}
		}
	`);

	const allTestimonialsData = data.cms.testimonials;
	let selectedTestimonials = [];

	allTestimonialsData.forEach(testimonial => {
		if (testimonialsFound.indexOf(testimonial.heading) > -1) {
			selectedTestimonials.push(testimonial);
		}
	});

	if (selectedTestimonials.length === 0 || testimonialsFound.length === 0) {
		return <></>;
	}

	return (
		<div
			className={classNames(
				classes.container,
				themes[theme],
				flushTop ? classes.flushTop : ""
			)}
		>
			{selectedTestimonials.map((testimonial, i) => {
				return (
					<div key={i} className={classes.slides}>
						<blockquote>
							<QuoteMark />
							<div className="body-medium">{parse(testimonial.bodytext)}</div>
							<footer>
								{testimonial.heading}{" "}
								<cite className="h6">{testimonial.subheading}</cite>
								{/* <Logo /> */}
								{testimonial.logo && (
									<img
										src={testimonial.logo.logo}
										alt={testimonial.heading}
										loading="lazy"
										onLoad={() => {
											requestAnimationFrame(() => {
												ScrollController.resize();
											});
										}}
									/>
								)}
							</footer>
						</blockquote>
					</div>
				);
			})}
		</div>
	);
};

export default Testimonial;
