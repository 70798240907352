import React, { useEffect, useState } from "react";
import classNames from "classnames";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";

const DetailPageHero: React.FC<{
	heading: string;
	foregroundImage?: string;
	foregroundImageAlt?: string;
	backgroundImage: string;
	backgroundImageAlt?: string;
	backgroundColor?: string;
	headingSize?: string;
	vimeoId?: string;
	blur?: string | boolean;
}> = ({
	heading,
	children,
	foregroundImage,
	foregroundImageAlt,
	backgroundImage,
	backgroundImageAlt,
	backgroundColor = "var(--brand-black)",
	headingSize = "",
	vimeoId,
	blur = "150",
}) => {
	const [isVisible, setIsVisible] = useState(false);

	if (vimeoId) {
		vimeoId = vimeoId.replace("https://vimeo.com/", "");
	}

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
		[classes.withForeground]: foregroundImage,
	});

	return (
		<div
			className={containerClass}
			style={{ backgroundColor: backgroundColor }}
		>
			<div className={classes.wrapper}>
				<div className={classes.text}>
					<h1 className={headingSize}>{heading}</h1>
				</div>
				{(foregroundImage || vimeoId) && (
					<div className={classes.foreground}>
						<Asset
							width={1325}
							height={642}
							src={foregroundImage}
							alt={foregroundImageAlt}
							vimeoId={vimeoId}
						/>
					</div>
				)}
				<div className={classes.background}>
					<Asset
						width={1440}
						src={backgroundImage}
						alt={backgroundImageAlt}
						params={`blur=${blur}`}
					/>
				</div>
			</div>
			<div className={classes.details}>
				<div className={classes.detailsWrapper}>{children}</div>
			</div>
		</div>
	);
};

export default DetailPageHero;
