import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import classes from "./styles.module.scss";

import AnimationController from "../../utils/controller-animation";
import ScrollController from "../../utils/controller-scroll";

import LogoType from "../../assets/vectors/logo-type.svg";
import LogoIcon from "../../assets/vectors/logo-icon.svg";
import Hamburger from "../../assets/vectors/icons/hamburger.svg";

import { NavItem } from "../header";

const MainNav: React.FC<{
	theme: String;
	pathname: String;
	toggleMobileNav: Function;
	items: NavItem[];
}> = ({ theme, pathname, toggleMobileNav, items }) => {
	const isHome = pathname == "/" ? true : false;
	const scrollStateOffsetTop = 75;
	const scrollStateTruncated = 500;
	const [scrollState, setScrollState] = useState(classes.isTop);

	let activeScrollState = classes.isTop;
	let nextScrollState = classes.isTop;

	const onUpdate = () => {
		const scroll = ScrollController.top;

		if (scroll >= scrollStateOffsetTop) {
			nextScrollState = classes.isScrolled;
		}

		// truncate after scrolling
		if (ScrollController.direction === 1 && scroll >= scrollStateTruncated) {
			nextScrollState = `${classes.isTruncated} ${classes.isScrolled}`;
		}

		// remove truncation when scrolling up
		if (ScrollController.direction === -1 && scroll >= scrollStateTruncated) {
			nextScrollState = classes.isScrolled;
		}

		if (scroll < scrollStateOffsetTop) {
			nextScrollState = classes.isTop;
		}

		if (nextScrollState !== activeScrollState) {
			return true;
		}

		return false;
	};

	const onRender = () => {
		activeScrollState = nextScrollState;
		setScrollState(activeScrollState);
	};

	const themes = {
		light: classes.themeLight,
		dark: classes.themeDark,
		transparent: classes.themeTransparent,
	};

	const containerClass = classNames(
		classes.container,
		themes[theme],
		scrollState,
		{
			[classes.themeHome]: isHome,
		}
	);

	const showNav = () => {
		toggleMobileNav(true);
	};

	const hideNav = () => {
		toggleMobileNav(false);
	};

	useEffect(() => {
		const animation = AnimationController.set({
			update: onUpdate,
			render: onRender,
		});

		return () => {
			AnimationController.clear(animation);
		};
	}, []);

	return (
		<div className={containerClass}>
			<div className={classes.items}>
				<div className={classes.logo}>
					<Link
						to="/"
						onClick={hideNav}
						data-cursor-layer="main"
						data-cursor-face="dot"
						data-cursor-hitbox=""
						data-cursor-disabled="false"
						aria-label="Metajive homepage"
					>
						<span className={classes.logoType}>
							<LogoType />
							<span></span>
						</span>
						<span className={classes.logoIcon}>
							<LogoIcon />
						</span>
					</Link>
				</div>
				<nav>
					<ul>
						{items &&
							items.map((item, i) => (
								<li key={i}>
									<Link
										to={item.url}
										onClick={hideNav}
										data-cursor-layer="main"
										data-cursor-face="dot"
										data-cursor-hitbox=""
										data-cursor-disabled="false"
										activeClassName="is-active"
										partiallyActive={true}
									>
										<span className="h6">{item.label}</span>
										<span className={classes.description}>
											{item.description}
										</span>
									</Link>
								</li>
							))}
					</ul>
				</nav>
				<button className={classes.hamburger} onClick={showNav}>
					<figure>
						<Hamburger />
					</figure>
				</button>
			</div>
			<div className={classes.background}></div>
		</div>
	);
};

export default MainNav;
