import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { formatServices } from "../utils";

import StandardHero from "../components/heroes/standard";
import CompanyPageExcerpt from "../components/excerpts/company-page";
import ServicesOverview from "../components/overviews/services";
import PartnersOverview from "../components/overviews/partners";
import CompanyValues from "../components/company-values";
import FullWidthMedia from "../components/full-width-media";
import ExpertiseOverview from "../components/overviews/expertise";
import AwardsOverview from "../components/overviews/awards";
import ThreeByTwoPhotoGrid from "../components/grids/photo-grid/three-by-two";
import CareersBlurb from "../components/text-blocks/careers-blurb";

export const query = graphql`
	query CompanyPageQuery {
		cms {
			company_page {
				url
				seo {
					title
					description
					thumbnail
				}
				companyPagehero {
					headline
					image
					alt
					vimeoId
				}
				companyPageSubHeader {
					headline
					body_copy
					image
					alt
					vimeoId
				}
				companyPageService {
					body_copy
					cta_text
					cta_link
					service_category_details {
						name
						url
						category {
							name
							url
						}
					}
				}
				companyPageValueOverview {
					headline
					body_copy
					companyPageValue {
						image
						alt
						vimeoId
						headline
						eyebrow
						caption
					}
				}
				companyPageAsset {
					image
					alt
					vimeoId
				}
				companyPagePartnerOverview {
					headline
					body_copy
				}
				companyPageCertificationOverview {
					headline
					body_copy
					grid_header
				}
				companyPageAwardOverview {
					headline
					button_cta
				}
				companyPageJoinUs {
					headline
					body_copy
					cta_body
					cta_link
					companyPageJoinUsAsset {
						image
						alt
						position
						vimeoId
					}
				}
				prefooter {
					eyebrow
					sub_headline
					body_copy
					button_copy
					button_link
					show_newsletter_signup
				}
			}
			partners {
				partnerlogo {
					hexCode
					logo
				}
				name
			}
			expertise_and_certifications {
				body_copy
				hover_logo
				name
				non_hover_logo
			}
			awards {
				alt
				client
				logo
				name
			}
		}
	}
`;

const CompanyPage: React.FC = ({ data }) => {
	const {
		company_page,
		partners,
		expertise_and_certifications,
		awards,
	} = data.cms;
	const {
		seo,
		prefooter,
		companyPagehero,
		companyPageSubHeader,
		companyPageService,
		companyPageAsset,
		companyPageAwardOverview,
		companyPageValueOverview,
		companyPagePartnerOverview,
		companyPageCertificationOverview,
		companyPageJoinUs,
	} = company_page;

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout prefooter={prefooter} theme="transparent">
				<StandardHero
					heading={companyPagehero.headline}
					image={companyPagehero.image}
					imageAlt={companyPagehero.alt}
				/>
				<CompanyPageExcerpt
					heading={companyPageSubHeader.headline}
					body={companyPageSubHeader.body_copy}
					image={companyPageSubHeader.image}
					imageAlt={companyPageSubHeader.alt}
				/>
				<ServicesOverview
					bodyText={companyPageService.body_copy}
					services={formatServices(companyPageService.service_category_details)}
				/>
				<CompanyValues
					heading={companyPageValueOverview.headline}
					bodyText={companyPageValueOverview.body_copy}
					values={companyPageValueOverview.companyPageValue}
				/>
				<FullWidthMedia
					image={companyPageAsset.image}
					alt={companyPageAsset.alt}
					vimeoId={companyPageAsset.vimeoId}
				/>
				<PartnersOverview
					heading={companyPagePartnerOverview.headline}
					bodyText={companyPagePartnerOverview.body_copy}
					partners={partners}
				/>
				<ExpertiseOverview
					heading={companyPageCertificationOverview.headline}
					bodyText={companyPageCertificationOverview.body_copy}
					subheading={companyPageCertificationOverview.grid_header}
					expertise={expertise_and_certifications}
				/>
				<AwardsOverview
					heading={companyPageAwardOverview.headline}
					cta={companyPageAwardOverview.button_cta}
					awards={awards}
				/>
				{companyPageJoinUs && (
					<>
						<ThreeByTwoPhotoGrid
							assets={companyPageJoinUs.companyPageJoinUsAsset}
						/>
						<CareersBlurb
							heading={companyPageJoinUs.headline}
							body={companyPageJoinUs.body_copy}
							cta={companyPageJoinUs.cta_body}
							ctaUrl={companyPageJoinUs.cta_link}
						/>
					</>
				)}
			</Layout>
		</>
	);
};

export default CompanyPage;
