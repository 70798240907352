import "./src/assets/scss/theme/index.scss"

import AnimationController from "./src/utils/controller-animation"
import ScrollController from "./src/utils/controller-scroll"

AnimationController.start()

const onPreRouteUpdate = ({ location, prevLocation }) => {
	ScrollController.scrollTo(0, true)
}

export { onPreRouteUpdate }
