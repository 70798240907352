import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import { formatServices } from "../utils";

import HeroHome from "../components/heroes/home";
import FeaturedWorkCarousel from "../components/carousels/featured-work";
import ServicesOverview from "../components/overviews/services";
import PartnersOverview from "../components/overviews/partners";
import TestimonialsCarousel from "../components/carousels/testimonials";

export const query = graphql`
	query HomepageQuery {
		cms {
			case_studies {
				name
				url
				feature_in_homepage
				caseStudyPreviewContent {
					eyebrow
					headline
					image
					alt
					vimeoId
					feature_in_work_landing_page
				}
			}
			case_studies_lite {
				name
				url
				feature_in_homepage
				caseStudyPreviewContent {
					eyebrow
					headline
					image
					alt
					vimeoId
					feature_in_work_landing_page
				}
			}
			homepage {
				title
				url
				seo {
					title
					thumbnail
					description
				}
				overview {
					overviewtextblock {
						heading
						bodytext
					}
					partners {
						name
						partnerlogo {
							logo
							hexCode
						}
					}
				}
				hero {
					headline
					headline_position
					subheadline
					subheadline_position
					body_copy
					body_copy_position
					bottom_text
					bottom_text_position
					bottom_vimeoId
					loop
					HeroForegroundImage {
						image
						alt
						width
						height
						horizontal_position
						vertical_position
					}
					HeroMidgroundImage {
						image
						alt
						width
						height
						horizontal_position
						vertical_position
					}
					Herobackground {
						hexCode
						image
						alt
						horizontal_position
						vertical_position
						video_url
					}
				}
				pagetestimonials {
					heading
					subheading
					bodytext
					active
					logo {
						logo
						hexCode
					}
					media {
						image
						video
					}
				}
				powerStatement {
					headline
					body_copy
				}
				service_category_details {
					name
					url
					category {
						name
						url
					}
					show_homepage
					show_company
					active
					fields
					seo {
						title
						thumbnail
						description
					}
					##You can review all the fields available in the Service_category_details endpoint
				}
				case_studies {
					name
					url
					seo {
						title
						thumbnail
						description
					}
					caseStudyPreviewContent {
						eyebrow
						headline
						image
						alt
						vimeoId
						feature_in_work_landing_page
					}
					caseStudyHero {
						eyebrow
						headline
						image
						alt
						vimeoId
						cta_copy
						cta_link
					}
					##You can review all the fields available in the case_study endpoint
				}
				prefooter {
					eyebrow
					sub_headline
					body_copy
					button_copy
					button_link
					show_newsletter_signup
				}
			}
		}
	}
`;

const HomePage: React.FC = ({ location, data, pageContext }) => {
	const page = data.cms.homepage;
	const {
		seo,
		hero,
		overview,
		pagetestimonials,
		powerStatement,
		service_category_details,
		prefooter,
	} = page;

	const { case_studies, case_studies_lite } = data.cms;
	const filterByFeatureInHomePage = item => item.feature_in_homepage;
	const selectedCaseStudies = case_studies.filter(filterByFeatureInHomePage);
	const selectedCaseStudiesLite = case_studies_lite.filter(filterByFeatureInHomePage);
	const homePageCaseStudies = [...selectedCaseStudiesLite, ...selectedCaseStudies, ];

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>

			<Layout location={location} theme="home" prefooter={prefooter}>
				<HeroHome
					heading={hero.headline}
					align={hero.headline_position}
					subhead={hero.subheadline}
					subheadAlign={hero.subheadline_position}
					bodyText={hero.body_copy}
					bodyTextAlign={hero.body_copy_position}
					foregroundAlignX={hero.HeroForegroundImage.horizontal_position}
					foregroundAlignY={hero.HeroForegroundImage.vertical_position}
					foregroundImage={hero.HeroForegroundImage.image}
					foregroundImageAlt={hero.HeroForegroundImage.alt}
					backgroundImage={hero.Herobackground.image}
					backgroundImageAlt={hero.Herobackground.alt}
					backgroundVideo={hero.Herobackground.video_url}
					backgroundColor={hero.Herobackground.hexCode}
					middlegroundImage={hero.HeroMidgroundImage.image}
					middlegroundImageAlt={hero.HeroMidgroundImage.alt}
					foregroundImageWidth={hero.HeroForegroundImage.width}
					foregroundImageHeight={hero.HeroForegroundImage.height}
					middlegroundImageWidth={hero.HeroMidgroundImage.width}
					middlegroundImageHeight={hero.HeroMidgroundImage.height}
					loop={hero.loop}
				/>

				{homePageCaseStudies && homePageCaseStudies.length > 0 && (
					<FeaturedWorkCarousel caseStudies={homePageCaseStudies} />
				)}

				{service_category_details && (
					<ServicesOverview
						heading={powerStatement.headline}
						bodyText={powerStatement.body_copy}
						services={formatServices(service_category_details)}
					/>
				)}

				{overview && overview.partners && (
					<PartnersOverview
						heading={overview.overviewtextblock.heading}
						bodyText={overview.overviewtextblock.bodytext}
						partners={overview.partners}
					/>
				)}

				{pagetestimonials && (
					<TestimonialsCarousel testimonials={pagetestimonials} />
				)}
			</Layout>
		</>
	);
};

export default HomePage;
