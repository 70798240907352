import React, { useState } from "react";
import SwiperCore, { EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Testimonial, { TestimonialThemes } from "../../testimonials/testimonial";
import TestimonialWithImage, {
	TestimonialWithImageInterface,
} from "../../testimonials/with-image";

import classes from "./styles.module.scss";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([EffectFade, Pagination]);

const TestimonialsCarousel: FC<{
	testimonials: TestimonialWithImageInterface[];
}> = ({ testimonials }) => {
	const [swiperInstance, setSwiperInstance] = useState(null);
	const isTouch =
		swiperInstance &&
		(swiperInstance.device.ios || swiperInstance.device.android)
			? true
			: false;

	if (testimonials.length === 1) {
		return testimonials.map((testimonial, i) => (
			<SwiperSlide key={i} className={classes.slide}>
				{testimonial.media && (
					<TestimonialWithImage
						heading={testimonial.heading}
						eyebrow={testimonial.subheading}
						body={testimonial.bodytext}
						media={testimonial.media}
						logo={testimonial.logo}
					/>
				)}
				{!testimonial.media && (
					<Testimonial
						testimonials={[testimonial]}
						theme={TestimonialThemes.light}
					/>
				)}
			</SwiperSlide>
		));
	}

	return (
		<div className={classes.container}>
			<Swiper
				pagination={{ type: "bullets", clickable: true }}
				loop={true}
				grabCursor={true}
				watchOverflow={true}
				onSlideChangeTransitionEnd={() => {
					window.dispatchEvent(new Event("scroll"));
				}}
				touchStartPreventDefault={!!isTouch}
				onSwiper={setSwiperInstance}
				data-cursor-layer="main"
				data-cursor-face="drag"
				data-cursor-hitbox=""
				data-cursor-disabled="false"
			>
				{testimonials.map((testimonial, i) => (
					<SwiperSlide key={i} className={classes.slide}>
						{testimonial.media && (
							<TestimonialWithImage
								heading={testimonial.heading}
								eyebrow={testimonial.subheading}
								body={testimonial.bodytext}
								media={testimonial.media}
								logo={testimonial.logo}
							/>
						)}
						{!testimonial.media && (
							<Testimonial
								testimonials={[testimonial]}
								theme={TestimonialThemes.light}
							/>
						)}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default TestimonialsCarousel;
