import React from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import StandardCarousel from "../standard";
import Asset from "../../ui/asset";

interface Attributes {
	image: string;
	alt: string;
	vimeoId: string;
	caption: string;
}
interface GalleryAsset {
	attributes: Attributes;
}

const ImageCarousel: React.FC<{ gallery_asset: GalleryAsset[] }> = ({
	gallery_asset,
}) => (
	<div className={classes.container}>
		<StandardCarousel inset={true}>
			{gallery_asset.map((item, i) => (
				<div key={i} className={classes.slide}>
					<div className={classes.slideImage}>
						<Asset src={item.attributes.image} alt={item.attributes.alt} />
					</div>
					<div className={classNames(classes.slideText, "eyebrow")}>
						{parse(item.attributes.caption)}
					</div>
				</div>
			))}
		</StandardCarousel>
	</div>
);

export default ImageCarousel;
