import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import "../../ui/asset";
import Asset from "../../ui/asset";

const CompanyPageExcerpt: React.FC<{
	heading: string;
	body: string;
	image: string;
	imageAlt: string;
}> = ({ heading, body, image, imageAlt }) => (
	<div className={classes.container}>
		<span className={classes.tab}></span>
		<div className={classes.textWrapper}>
			<p className="h4">{heading}</p>
			<div className={classes.bodyText}>{parse(body)}</div>
		</div>
		<div className={classes.mediaWrapper}>
			<Asset src={image} alt={imageAlt} />
		</div>
	</div>
);

export default CompanyPageExcerpt;
