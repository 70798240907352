import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import classNames from "classnames";

import ArrowRight from "../../assets/vectors/icons/arrow-right.svg";
import SodaLogo from "../../../static/images/logo-soda.png";

import classes from "./styles.module.scss";

interface FooterLink {
	name: string;
	url: string;
}

const Footer: React.FC<{
	showMain?: Boolean;
	links: FooterLink[];
	socialLinks: FooterLink[];
	newsletterEyebrow: string;
	addressEyebrow: string;
	addressLine1: string;
	addressLine2: string;
	sayHelloEyebrow: string;
	sayHelloLine1: string;
	sayHelloLine2: string;
}> = ({
	showMain = true,
	links,
	socialLinks,
	newsletterEyebrow,
	addressEyebrow,
	addressLine1,
	addressLine2,
	sayHelloEyebrow,
	sayHelloLine1,
	sayHelloLine2,
}) => {
	return (
		<footer id="footer" className={classes.container}>
			{showMain && (
				<div className={classes.wrapper}>
					<div className={classes.primaryNav}>
						<ul className="h2">
							{links.map((link, i) => (
								<li key={i}>
									<Link to={link.url}>
										<span aria-hidden={true} className={classes.primaryNavIcon}>
											/
										</span>
										<span className={classes.primaryNavText}>{link.name}</span>
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className={classes.secondaryNav}>
						<div>
							<span className={classNames("eyebrow", classes.eyebrow)}>
								{sayHelloEyebrow}
							</span>
							<div>
								<a href={`mailto:${sayHelloLine1}`}>{sayHelloLine1}</a>
								<br />
								<a href={`tel:${sayHelloLine2}`}>{sayHelloLine2}</a>
							</div>
						</div>

						<div>
							<span className={classNames("eyebrow", classes.eyebrow)}>
								{addressEyebrow}
							</span>
							<div>
								<p>
									{addressLine1}
									<br />
									{addressLine2}
								</p>
							</div>
						</div>

						<div>
							<form action="#">
								<label
									htmlFor="email"
									className={classNames("eyebrow", classes.eyebrow)}
								>
									{newsletterEyebrow}
								</label>
								<div className={classes.inputWrapper}>
									<input id="email" type="text" placeholder="name@email.com" />
									<button type="submit" className={classes.submit}>
										<figure>
											<ArrowRight />
										</figure>
									</button>
								</div>
							</form>
						</div>

						<div>
							<ul className={classNames(classes.social, "metadata")}>
								{socialLinks.map((link, i) => (
									<li key={i}>
										<a href={link.url} target="_blank" rel="noreferrer">
											{link.name}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			)}
			<div className={classes.copyright}>
				<div className={classes.sodaWrapper}>
					<a
						href="https://www.sodaspeaks.com/"
						target="_blank"
						rel="noreferrer"
					>
						<img
							src={SodaLogo}
							alt="Proud SoDA Member"
							width="188"
							height="34"
						/>
					</a>
				</div>
				<div className={classes.copyrightWrapper}>
					<p className="eyebrow">
						&copy;{new Date().getFullYear()} Metajive. All Rights Reserved.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
