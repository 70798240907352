import React from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import Asset from "../../ui/asset";

import classes from "./styles.module.scss";

const TextBlockImageDescription: React.FC<{
	heading?: String;
	bodyText: string;
	theme?: String;
}> = ({ heading, bodyText = "", theme = "light" }) => {
	const themes = {
		light: "",
		dark: classes.themeDark,
	};

	return (
		<>
			{heading && (
				<p className={classNames(classes.heading, themes[theme])}>{heading}</p>
			)}
			{bodyText && (
				<div className={classNames(classes.bodyText, themes[theme])}>
					{parse(bodyText)}
				</div>
			)}
		</>
	);
};

export default TextBlockImageDescription;
