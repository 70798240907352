import React, { useState } from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import ScrollController from "../../utils/controller-scroll";

import Asset from "../ui/asset";
import StandardCarousel from "../carousels/standard";

import XIcon from "../../assets/vectors/icons/x.svg";

interface GalleryAsset {
	image: string;
	alt: string;
	caption: string;
	vimeoId: string;
}
interface GalleryItem {
	eyebrow: string;
	caseStudyDetailsWeLoveGalleryAsset: GalleryAsset[];
}

const DetailsWeLove: React.FC<{
	heading: string;
	galleryItems: GalleryItem[];
	isActive?: boolean;
	gapBetweenSlides?: boolean;
}> = ({ heading, galleryItems, isActive = false, gapBetweenSlides }) => {
	const [toggleActive, setToggleActive] = useState(isActive);

	const handleToggle = () => {
		setToggleActive(!toggleActive);
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 100);
	};

	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<p className="h1">{heading}</p>
			</div>
			<button
				data-sticky
				data-sticky-offset
				className={classNames(classes.toggle, {
					[classes.toggleActive]: toggleActive,
				})}
				onClick={() => {
					handleToggle();
				}}
			>
				<span>{toggleActive ? "Show" : "Hide"} Content</span>
				<XIcon />
			</button>
			<div
				className={classNames(classes.content, {
					[classes.contentActive]: toggleActive,
				})}
			>
				{galleryItems.map((item, i) => {
					return (
						<div key={i} className={classes.detailsWrapper}>
							<p className="h6">{item.eyebrow}</p>
							{item.caseStudyDetailsWeLoveGalleryAsset.length === 1 && (
								<div className={`${classes.details} ${item.caseStudyDetailsWeLoveGalleryAsset[0].full_bleed_image ? classes.fullBleedImage : ''}`}>
									<figure>
										<Asset
											width={803}
											src={item.caseStudyDetailsWeLoveGalleryAsset[0].image}
											alt={item.caseStudyDetailsWeLoveGalleryAsset[0].alt}
											vimeoId={
												item.caseStudyDetailsWeLoveGalleryAsset[0].vimeoId
											}
											fullSizeMode={item.caseStudyDetailsWeLoveGalleryAsset[0].full_bleed_image ? 'full-width' : 'regular'}
										/>
									</figure>
								</div>
							)}

							{item.caseStudyDetailsWeLoveGalleryAsset.length > 1 && (
								<div className={classes.detailsCarousel}>
									<StandardCarousel hasSpaceBetween={gapBetweenSlides}>
										{item.caseStudyDetailsWeLoveGalleryAsset.map((image, i) => {
											return (
												<div key={i} className={classes.carouselSlide}>
													<div className={classes.carouselSlideImage}>
														<Asset
															width={749}
															height={467}
															src={image.image}
															alt={image.alt}
															vimeoId={image.vimeoId}
															resizeVideo={false}
														/>
													</div>
													<div
														className={classNames(
															classes.carouselSlideCaption,
															"metadata"
														)}
													>
														{image.caption && parse(image.caption)}
													</div>
												</div>
											);
										})}
									</StandardCarousel>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DetailsWeLove;
