import React, { ReactNode } from "react"
import classNames from "classnames"

import classes from "./styles.module.scss"

const HeroText: React.FC<{
	heading: String
	eyebrow?: String
	bodyText: ReactNode
}> = ({ heading, eyebrow, bodyText }) => (
	<>
		{eyebrow && (
			<p className={classNames("eyebrow", classes.eyebrow)}>{eyebrow}</p>
		)}
		<h1 className={classes.heading}>{heading}</h1>
		<div className={classes.bodyText}>{bodyText}</div>
	</>
)

export default HeroText
