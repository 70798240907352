import React from "react";

import Asset from "../../ui/asset";
import classes from "./styles.module.scss";

import StackedTitle from "../stacked-title";

const TextBlockLargeImage: React.FC<{
	heading?: string;
	body?: string;
	image?: string;
	imageFirst?: boolean;
	vimeoId?: string;
	alt?: string;
	fullBleedImage?: boolean;
}> = ({ heading, body, image, imageFirst = false, vimeoId, alt, fullBleedImage= false }) => {
	return (
		<div className={`${classes.container} ${fullBleedImage ? classes.fullBleed : ''}`}>
			{imageFirst && image && (
				<div className={classes.imageWrapper}>
					<Asset
						src={image}
						width={1440}
						alt={alt}
						vimeoId={vimeoId}
						resizeVideo={false}
						fullSizeMode="full-width"
					/>
				</div>
			)}
			<div className={classes.textWrapper}>
				<StackedTitle heading={heading} bodyText={body} />
			</div>
			{!imageFirst && image && (
				<div className={classes.imageWrapper}>
					<Asset
						src={image}
						width={1440}
						alt={alt}
						vimeoId={vimeoId}
						resizeVideo={false}
						fullSizeMode="full-width"
					/>
				</div>
			)}
		</div>
	);
};

export default TextBlockLargeImage;
