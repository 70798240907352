import React from "react";
import classNames from "classnames";
import classes from "./styles.module.scss";

const HeadingTextBlock: React.FC<{
	heading?: string;
	sub_heading?: string;
}> = ({ heading, sub_heading }) => {
	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				{heading && <p className={classNames(classes.h2, "h2")}>{heading}</p>}
				{sub_heading && (
					<p className={classNames(classes.h4, "h4")}>{sub_heading}</p>
				)}
			</div>
		</div>
	);
};

export default HeadingTextBlock;
