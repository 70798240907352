import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ContactModule from "../components/contact";

export const query = graphql`
	query ContactPageQuery {
		cms {
			contact_page {
				url
				seo {
					title
					description
					thumbnail
				}
				section1 {
					eyebrow
					email
					phone
				}
				section2 {
					eyebrow
					line1
					line2
				}
				section3 {
					eyebrow
					email
					phone
				}
				section4 {
					eyebrow
					email
					phone
				}
				careerSection {
					image
					alt
					vimeoId
					cta_text
					cta_link
				}
			}
			footer {
				social {
					url
					name
				}
			}
		}
	}
`;

const ContactPage: React.FC = ({ data }) => {
	const { contact_page, footer } = data.cms;
	const { seo } = contact_page;

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout footer={false}>
				<ContactModule
					section1Eyebrow={contact_page.section1.eyebrow}
					section1Email={contact_page.section1.email}
					section1Phone={contact_page.section1.phone}
					section2Eyebrow={contact_page.section2.eyebrow}
					section2Line1={contact_page.section2.line1}
					section2Line2={contact_page.section2.line2}
					section3Eyebrow={contact_page.section3.eyebrow}
					section3Email={contact_page.section3.email}
					section3Phone={contact_page.section3.phone}
					section4Eyebrow={contact_page.section4.eyebrow}
					section4Email={contact_page.section4.email}
					section4Phone={contact_page.section4.phone}
					careersImage={contact_page.careerSection.image}
					careersImageAlt={contact_page.careerSection.alt}
					careersCTA={contact_page.careerSection.cta_text}
					careersCTAUrl={contact_page.careerSection.cta_link}
					social={footer[0].social}
				/>
			</Layout>
		</>
	);
};

export default ContactPage;
