import React from "react";

import Layout from "../components/layout";

import FourOhFourModule from "../components/404";

const FourOhFourPage: React.FC = () => (
	<Layout theme="dark">
		<FourOhFourModule />
	</Layout>
);

export default FourOhFourPage;
