import React, { useEffect, ReactElement } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import ArrowRight from "../../../../assets/vectors/icons/arrow-right.svg";

import classes from "./styles.module.scss";

export enum themes {
	LIGHT = "light",
	DARK = "dark",
}

const CTAArrowRound: React.FC<{
	url?: string;
	label: string;
	icon?: ReactElement;
	mailto?: string;
	cursorFace?: string;
	onClick?: () => void;
	theme?: string;
}> = props => {
	const {
		url,
		label,
		icon,
		mailto,
		cursorFace = "circle",
		theme = themes.DARK,
		...restProps
	} = props;

	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 500);
	}, []);

	if (mailto) {
		return (
			<a
				href={mailto}
				className={classNames(classes.container, classes[theme])}
				target="_blank"
				{...restProps}
			>
				<span
					className={classes.icon}
					data-cursor-layer="main"
					data-cursor-face={cursorFace}
					data-cursor-disabled="false"
					data-cursor-fixed
				>
					<figure>
						{icon}
						{!icon && <ArrowRight />}
					</figure>
				</span>
				<span className={classes.label}>{label}</span>
			</a>
		);
	}

	if (!url) {
		return (
			<button
				className={classNames(classes.container, classes[theme])}
				{...restProps}
			>
				<span
					className={classes.icon}
					data-cursor-layer="main"
					data-cursor-face={cursorFace}
					data-cursor-disabled="false"
					data-cursor-fixed
					data-log
				>
					<figure>
						{icon}
						{!icon && <ArrowRight />}
					</figure>
				</span>
				<span className={classes.label}>{label}</span>
			</button>
		);
	}

	return (
		<Link
			to={url}
			className={classNames(classes.container, classes[theme])}
			{...restProps}
		>
			<span
				className={classes.icon}
				data-cursor-layer="main"
				data-cursor-face={cursorFace}
				data-cursor-disabled="false"
				data-cursor-fixed
				data-log
			>
				<figure>
					{icon}
					{!icon && <ArrowRight />}
				</figure>
			</span>
			<span className={classes.label}>{label}</span>
		</Link>
	);
};

export default CTAArrowRound;
