// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-approach-detail-tsx": () => import("./../../../src/templates/approach-detail.tsx" /* webpackChunkName: "component---src-templates-approach-detail-tsx" */),
  "component---src-templates-career-detail-tsx": () => import("./../../../src/templates/career-detail.tsx" /* webpackChunkName: "component---src-templates-career-detail-tsx" */),
  "component---src-templates-case-study-lite-tsx": () => import("./../../../src/templates/case-study-lite.tsx" /* webpackChunkName: "component---src-templates-case-study-lite-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-insights-detail-tsx": () => import("./../../../src/templates/insights-detail.tsx" /* webpackChunkName: "component---src-templates-insights-detail-tsx" */),
  "component---src-templates-insights-landing-tsx": () => import("./../../../src/templates/insights-landing.tsx" /* webpackChunkName: "component---src-templates-insights-landing-tsx" */),
  "component---src-templates-service-category-tsx": () => import("./../../../src/templates/service-category.tsx" /* webpackChunkName: "component---src-templates-service-category-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/service-detail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */)
}

