import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";

import { pathJoin } from "../../utils";
import classes from "./styles.module.scss";

import ArrowRight from "../../assets/vectors/icons/arrow-right.svg";
import Asset from "../ui/asset";

interface JobPosting {
	name: string;
	url: string;
	careerDetailPagePosition: {
		description: string;
		position_title: string;
	};
}

const CareersListing: React.FC<{
	heading?: string;
	body?: string;
	image?: string;
	alt?: string;
	jobsTableHeading: string;
	jobsTableEyebrow: string;
	jobPostings: JobPosting[];
}> = ({
	heading,
	body,
	image,
	alt,
	jobsTableHeading,
	jobsTableEyebrow,
	jobPostings,
}) => {
	if (jobPostings.length === 0) {
		return <></>;
	}

	return (
		<div className={classes.container}>
			<span id="job-openings" className={classes.scrollTarget}></span>
			{heading && (
				<div className={classes.heading}>
					<div className={classes.headingWrapper}>
						<h2>{heading}</h2>
						<div className={classes.bodyText}>{parse(body)}</div>
					</div>
					<div className={classes.imageContainer}>
						<Asset src={image} alt={alt} />
					</div>
				</div>
			)}
			<div className={classes.positions}>
				<p className="metadata">{jobsTableEyebrow}</p>
				<p className="h3">{jobsTableHeading}</p>
				<ul>
					{jobPostings.map((jobPosting, i) => {
						return (
							<li key={i}>
								<div className={classes.textWrapper}>
									<span className="h5">
										<Link to={pathJoin(["/careers", jobPosting.url])}>
											{jobPosting.careerDetailPagePosition.position_title}
										</Link>
									</span>
									<span className={classes.jobType}>
										{parse(jobPosting.careerDetailPagePosition.description)}
									</span>
								</div>
								<span className={classes.icon}>
									<ArrowRight />
								</span>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default CareersListing;
