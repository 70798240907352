import React, { useState } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";

import classes from "./styles.module.scss";

import StackedTitle from "../../text-blocks/stacked-title";

SwiperCore.use([Autoplay]);

interface Logo {
	hexCode: string;
	logo: string;
}
interface Partner {
	name: string;
	partnerlogo: Logo;
}

const PartnersOverview: React.FC<{
	heading: String;
	bodyText: String;
	partners?: Array<Partner>;
	theme?: String;
}> = ({ heading, bodyText, partners, theme }) => {
	const [swiperInstance, setSwiperInstance] = useState(null);
	const isTouch =
		swiperInstance &&
		(swiperInstance.device.ios || swiperInstance.device.android)
			? true
			: false;

	return (
		<div
			className={(classes.container, theme && theme === "dark" && classes.dark)}
		>
			{heading && (
				<div className={classes.heading}>
					<StackedTitle heading={heading} bodyText={bodyText} />
				</div>
			)}
			{partners && (
				<Swiper
					className={classes.partners}
					data-cursor-layer="main"
					data-cursor-face="drag"
					data-cursor-hitbox=""
					data-cursor-disabled="false"
					freeMode={true}
					slidesPerView="auto"
					grabCursor={true}
					onAutoplayStop={swiperInstance => {
						swiperInstance.el.classList.add(classes.isInteracted);
					}}
					autoplay={{
						delay: 0,
					}}
					speed={4050}
					touchStartPreventDefault={!!isTouch}
					onSwiper={setSwiperInstance}
					loop={true}
				>
					{partners &&
						partners.map((item, i) => {
							return (
								<SwiperSlide key={i}>
									<span
										className={classNames(
											classes.partnerLabel,
											theme && theme === "dark" && classes.darkSlide
										)}
									>
										/ {item.name}
									</span>
									<span
										className={classNames(
											classes.partnerIcon,
											theme && theme === "dark" && classes.darkIcon
										)}
									>
										<img src={item.partnerlogo.logo} alt={item.name} />
									</span>
									<span
										className={classes.partnerColor}
										style={{
											backgroundColor: item.partnerlogo.hexCode,
										}}
									></span>
								</SwiperSlide>
							);
						})}
				</Swiper>
			)}
		</div>
	);
};

export default PartnersOverview;
