import React from "react";

import classes from "./styles.module.scss";

import PhotoGrid from "../index";
import Asset from "../../../ui/asset";

interface Asset {
	image: string;
	alt: string;
	position: string;
	vimeoId: string;
}

const ThreeByTwoPhotoGrid: React.FC<{ assets: Asset[] }> = ({ assets }) => {
	return (
		<PhotoGrid>
			<div className={classes.row}>
				<Asset
					width={240}
					height={240}
					src={assets[0]?.image}
					alt={assets[0]?.alt}
				/>
				<Asset
					width={270}
					height={416}
					src={assets[1]?.image}
					alt={assets[1]?.alt}
				/>
				<Asset
					width={472}
					height={242}
					src={assets[2]?.image}
					alt={assets[2]?.alt}
				/>
			</div>
			<div className={classes.row}>
				<Asset
					width={347}
					height={196}
					src={assets[3]?.image}
					alt={assets[3]?.alt}
				/>
				<Asset
					width={204}
					height={392}
					src={assets[4]?.image}
					alt={assets[4]?.alt}
				/>
				<Asset
					width={405}
					height={256}
					src={assets[5]?.image}
					alt={assets[5]?.alt}
				/>
			</div>
		</PhotoGrid>
	);
};

export default ThreeByTwoPhotoGrid;
