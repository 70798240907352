import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";
import CTAArrowRound from "../ui/ctas/arrow-round";
import Asset from "../ui/asset";

const SideBySide: React.FC<{
	left_alt: string;
	left_body_copy: string;
	left_cta_copy: string;
	left_cta_link: string;
	left_headline: string;
	left_image: string;
	right_alt: string;
	right_body_copy: string;
	right_cta_copy: string;
	right_cta_link: string;
	right_headline: string;
	right_image: string;
}> = ({
	left_alt,
	left_body_copy,
	left_cta_copy,
	left_cta_link,
	left_headline,
	left_image,
	right_alt,
	right_body_copy,
	right_cta_copy,
	right_cta_link,
	right_headline,
	right_image,
}) => {
	return (
		<div className={classes.container}>
			<div className={classes.contentContainer}>
				<div className={classes.itemContainer}>
					<div className={classes.image}>
						<Asset width={496} height={279} src={left_image} alt={left_alt} />
					</div>
					<div className={classes.text}>
						<p className="h4">{left_headline}</p>
						<div className={classes.bodyText}>{parse(left_body_copy)}</div>
						<div className={classes.ctaWrapper}>
							<CTAArrowRound label={left_cta_copy} url={left_cta_link} />
						</div>
					</div>
				</div>
				<div className={classes.itemContainer}>
					<div className={classes.image}>
						<Asset width={496} height={279} src={right_image} alt={right_alt} />
					</div>
					<div className={classes.text}>
						<p className="h4">{right_headline}</p>
						<div className={classes.bodyText}>{parse(right_body_copy)}</div>
						<div className={classes.ctaWrapper}>
							<CTAArrowRound label={right_cta_copy} url={right_cta_link} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideBySide;
