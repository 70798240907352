import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import AnimationController from "../../../utils/controller-animation";
import ScrollController from "../../../utils/controller-scroll";
import CursorController from "../../../utils/controller-cursor";
// import CTA from "../../../../interfaces/cta";
import CTAArrowRound from "../../ui/ctas/arrow-round";
import ChevronUp from "../../../assets/vectors/icons/chevron-up.svg";

import { getRootOffset } from "../../../utils";

interface ListItem {
	name: string;
	url: string;
}
interface Service {
	heading: string;
	url: string;
	listItems: ListItem;
}

const ServicesOverview: React.FC<{
	heading?: string;
	bodyText: string;
	services: Array<Service>;
}> = ({ heading, bodyText, services }) => {
	const [activeItem, setActiveItem] = useState(null);
	const [offset, setOffset] = useState({ top: 0, left: 0 });
	const wrapper = useRef(null);
	const headingLine1 = useRef(null);
	const headingLine2 = useRef(null);

	const toggleItem = index => {
		setActiveItem(activeItem === index ? null : index);

		setTimeout(() => {
			CursorController.measureLayer();
		}, 100);
	};

	const onResize = () => {
		setOffset(getRootOffset(wrapper.current));
	};

	const onUpdate = () => {
		const winHeight = window.innerHeight;
		const sectionTop = offset.top;
		const sectionHeight = wrapper.current.offsetHeight + winHeight;
		const scrollTop = ScrollController.eased + winHeight;

		const scrollProgress = (scrollTop - sectionTop) / sectionHeight;

		if (scrollProgress > 0 && scrollProgress <= 1) {
			return true;
		}

		return false;
	};

	const onRender = () => {
		const winHeight = window.innerHeight;
		const sectionTop = offset.top;
		const sectionHeight = wrapper.current.offsetHeight + winHeight;
		const scrollTop = ScrollController.eased + winHeight;

		const scrollProgress = (scrollTop - sectionTop) / sectionHeight;
		const headingWidth = headingLine1.current.scrollWidth;
		const scale = (headingWidth / winHeight) * 100;

		headingLine1.current.style.transform = `translateX(${
			scale * -1 * scrollProgress
		}%)`;
		headingLine2.current.style.transform = `translateX(${
			scale * scrollProgress
		}%)`;
	};

	useEffect(() => {
		if (headingLine1.current && headingLine2.current) {
			const animation = AnimationController.set({
				update: onUpdate,
				render: onRender,
			});

			return () => {
				AnimationController.clear(animation);
			};
		}
	}, [offset]);

	useEffect(() => {
		window.addEventListener("resize", onResize);
		onResize();

		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return (
		<div ref={wrapper} className={classes.container}>
			<div className={classes.heading}>
				{heading && (
					<div className={classes.ticker}>
						<div className={classes.test}></div>
						<p ref={headingLine1} className="h1">
							{heading}
						</p>
						<p ref={headingLine2} className="h1">
							{heading}
						</p>
					</div>
				)}
				<div className={classes.bodyText}>{parse(bodyText)}</div>
			</div>
			<div className={classes.services}>
				{services.map((service, index) => {
					const isOpen = activeItem === index;
					const inactive = activeItem && activeItem !== index;

					return (
						<nav
							key={index}
							id={`${index}`}
							data-active={isOpen}
							data-inactive={inactive}
							className={classes.service}
						>
							<button
								className={classNames("h5", classes.toggle)}
								onClick={() => {
									toggleItem(index);
								}}
								data-cursor-layer="main"
								data-cursor-face="dot"
								data-cursor-hitbox=""
								data-cursor-disabled="false"
							>
								<span className={classes.toggleLabel}>{service.heading}</span>
								<span className={classes.toggleIcon}>
									<ChevronUp />
								</span>
							</button>
							<ul>
								{service.listItems.map((item, i) => (
									<li
										key={i}
										data-cursor-layer="main"
										data-cursor-face="dot"
										data-cursor-hitbox=""
										data-cursor-disabled="false"
									>
										<Link
											to={`/services${service.url}${item.url}`}
											className="eyebrow"
										>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</nav>
					);
				})}
			</div>
			<div className={classes.cta}>
				<CTAArrowRound
					label="All Services"
					url="/services/"
					data-cursor-layer="main"
					data-cursor-face="dot"
					data-cursor-disabled="false"
					data-cursor-hitbox
				/>
			</div>
		</div>
	);
};

export default ServicesOverview;
