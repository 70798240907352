import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { debounce } from "../../utils";
import CursorController from "../../utils/controller-cursor";

import classes from "./styles.module.scss";

import IconDrag from "../../assets/vectors/icons/drag.svg";
import ArrowRight from "../../assets/vectors/icons/arrow-right.svg";
import Plus from "../../assets/vectors/icons/plus.svg";

const CursorTakeover: React.FC<{}> = () => {
	const [isActive, setIsActive] = useState(false);
	let cursorRef = useRef(null);

	const onTouchStart = () => {
		document.documentElement.classList.add("is-touch");
		document.removeEventListener("touchstart", onTouchStart);
		CursorController.remove();
	};

	const onMouseMove = () => {
		CursorController.measureLayer();
	};

	useEffect(() => {
		if (typeof window !== `undefined`) {
			CursorController.cursor = cursorRef.current;
			CursorController.selector = "[data-cursor-layer]";
			CursorController.defaultLayer = "main";
			CursorController.collectAll();
			CursorController.start();

			const debouncedMouseMove = debounce(onMouseMove, 250, true);
			document.addEventListener("mousemove", debouncedMouseMove);
		}
	}, []);

	useEffect(() => {
		const onMouseMove = () => {
			setIsActive(true);
			document.removeEventListener("mousemove", debouncedMouseMove);
		};

		const debouncedMouseMove = debounce(onMouseMove, 250, true);
		document.addEventListener("mousemove", debouncedMouseMove);

		return () => {
			document.removeEventListener("mousemove", debouncedMouseMove);
		};
	}, []);

	return (
		<>
			<figure
				ref={cursorRef}
				className={classNames(
					classes.cursor,
					isActive ? classes.isActive : classes.isHidden
				)}
			>
				<div className={classNames(classes.icon, classes.iconDot)}>
					<span></span>
				</div>
				<div className={classNames(classes.icon, classes.iconArrowRight)}>
					<ArrowRight />
				</div>
				<div className={classNames(classes.icon, classes.iconPlus)}>
					<Plus />
				</div>
				<div className={classNames(classes.icon, classes.iconDrag)}>
					<IconDrag />
				</div>
				<div className={classNames(classes.icon, classes.iconButtonLeft)}></div>
				<div
					className={classNames(classes.icon, classes.iconButtonRight)}
				></div>
			</figure>
			<span id="cursor-dot" className={classes.dot}></span>
		</>
	);
};

export default CursorTakeover;
