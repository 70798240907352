import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PrefooterInterface } from "../components/pre-footer";

import ServiceDetailHero from "../components/heroes/service-detail";
import ServiceDetailExcerpt from "../components/excerpts/service-detail";
import RelatedContent from "../components/related-content";
import Testimonial from "../components/testimonials/testimonial";

import ModulesFromFields from "../utils/modules-from-fields";

const ServiceDetailPage: React.FC = ({ pageContext }) => {
	const { data } = pageContext;
	const {
		seo,
		prefooter,
		servicehero,
		serviceoverviews,
		serviceRelatedProjects,
		servicetestimonials,
		fields,
	} = data;

	const Modules = ModulesFromFields(fields);

	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="dark" prefooter={prefooter}>
				<ServiceDetailHero
					eyebrow={servicehero.eyebrow}
					heading={servicehero.headline}
					body={servicehero.body_copy}
					image={servicehero.image}
					imageAlt={servicehero.alt}
					vimeoId={servicehero.vimeoId}
				/>
				{serviceoverviews && (
					<ServiceDetailExcerpt
						heading={serviceoverviews.headline}
						body={serviceoverviews.body_copy}
					/>
				)}

				<div className="sections">
					<>{Modules}</>
				</div>

				{servicetestimonials && servicetestimonials.length > 0 && (
					<Testimonial
						theme="light"
						testimonials={servicetestimonials}
						flushTop={false}
					/>
				)}

				{serviceRelatedProjects && (
					<RelatedContent
						eyebrow={serviceRelatedProjects.eyebrow}
						heading={serviceRelatedProjects.headline}
						relatedItems={serviceRelatedProjects.ServiceRelatedProjects}
						urlBase="/work"
					/>
				)}
			</Layout>
		</>
	);
};

export default ServiceDetailPage;
