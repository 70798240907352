import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ModulesFromFields from "../utils/modules-from-fields";

import StandardHero from "../components/heroes/standard";
import InsightsDetailExcerpt from "../components/excerpts/insights-detail";
import RelatedContent from "../components/related-content";

const InsightsPage: React.FC = ({ pageContext }) => {
	const page = pageContext.data;
	const {
		seo,
		insightsDetailPageHero,
		insightsDetailPageIntro,
		InsightsDetailPageRelatedInsight,
		prefooter,
	} = page;

	const Modules = ModulesFromFields(page.fields);

	return (
		<>
			<Helmet>
				<meta name="twitter:label1" value="Posted" />
				<meta name="twitter:data1" value={page.date} />
				<meta name="twitter:label2" value="Article Type" />
				<meta name="twitter:data2" value={page.type} />
			</Helmet>

			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={true}
			/>
			<Layout theme="transparent" prefooter={prefooter}>
				<StandardHero
					heading={insightsDetailPageHero.headline}
					image={insightsDetailPageHero.image}
					imageAlt={insightsDetailPageHero.alt}
				/>
				<InsightsDetailExcerpt
					eyebrow={page.date}
					heading={insightsDetailPageIntro.headline}
					bodyText={insightsDetailPageIntro.body_copy}
				/>
				<div className="sections">
					<>{Modules}</>

					{InsightsDetailPageRelatedInsight && (
						<RelatedContent
							eyebrow={InsightsDetailPageRelatedInsight.eyebrow}
							heading={InsightsDetailPageRelatedInsight.headline}
							urlBase="/insights/"
							relatedItems={
								InsightsDetailPageRelatedInsight.insightsDetailPageRelatedInsights
							}
						/>
					)}
				</div>
			</Layout>
		</>
	);
};

export default InsightsPage;
