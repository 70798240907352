import React, { useState } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import classes from "./styles.module.scss";

import CarouselNavigation from "../../ui/carousel-navigation";

const StandardCarousel: React.FC<{
	children: any;
	inset?: boolean;
	style?: {};
	hasSpaceBetween?: boolean;
}> = ({ children, inset = false, style = {}, hasSpaceBetween }) => {
	const [swiperInstance, setSwiperInstance] = useState(null);

	const containerClass = classNames(
		classes.container,
		inset ? classes.inset : ""
	);

	return (
		<div className={containerClass} style={style}>
			<CarouselNavigation swiperInstance={swiperInstance} />
			<div className={classes.slides}>
				<Swiper
					onSwiper={setSwiperInstance}
					className={classes.swiperContainer}
					grabCursor={true}
					slidesPerView="auto"
					spaceBetween={hasSpaceBetween ? 40 : 0}
					breakpoints={{
						768: {
							spaceBetween: hasSpaceBetween ? 80 : 0,
						},
						1440: {
							spaceBetween: hasSpaceBetween ? 100 : 0,
						}
					}}
				>
					{children.map((child, i) => (
						<SwiperSlide key={i} className={classes.slide}>
							{child}
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default StandardCarousel;
