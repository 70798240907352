import React, { useEffect, useState, ReactElement } from "react";
import classNames from "classnames";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
import CTAArrowRound, { themes } from "../../ui/ctas/arrow-round";

const StandardHero: React.FC<{
	eyebrow?: String;
	heading: String;
	image: string;
	imageAlt: string;
	vimeoId?: string;
	cta?: string;
	ctaURL?: string;
	ctaIcon?: ReactElement;
	ctaOnClick?: () => void;
}> = ({
	eyebrow,
	heading,
	image,
	imageAlt,
	vimeoId,
	cta,
	ctaURL,
	ctaIcon,
	ctaOnClick,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const [formattedCTA, setFormattedCTA] = useState("");

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	useEffect(() => {
		// Strip out html tags in CTA
		const div = document.createElement("div");
		div.innerHTML = cta;
		setFormattedCTA(div.innerText);
	}, []);

	return (
		<div className={containerClass}>
			<div className={classes.textWrapper}>
				{eyebrow && <span className="eyebrow">{eyebrow}</span>}
				<h1>{heading}</h1>
				{cta && (
					<div className={classes.ctaWrapper}>
						<CTAArrowRound
							label={formattedCTA}
							theme={themes.LIGHT}
							icon={ctaIcon}
							onClick={ctaOnClick}
						/>
					</div>
				)}
			</div>
			<div className={classes.mediaWrapper}>
				{image && (
					<Asset width={1440} height={768} src={image} alt={imageAlt} />
				)}
			</div>
		</div>
	);
};

export default StandardHero;
