import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";

import "swiper/swiper.scss";
import classes from "./style.module.scss";

import AnimationController from "../../../utils/controller-animation";
import CarouselNavigation from "../../ui/carousel-navigation";
import CTAInlineDash from "../../ui/ctas/inline-dash";
import Asset from "../../ui/asset";

import { pathJoin } from "../../../utils";

interface PreviewContent {
	alt: string;
	eyebrow: string;
	headline: string;
	feature_in_work_landing_page: boolean;
	image: string;
	vimeoId: string;
}
interface Slide {
	name: string;
	url: string;
	caseStudyPreviewContent: PreviewContent;
}

const FeaturedWorkCarousel: React.FC<{ caseStudies: Slide[] }> = ({
	caseStudies,
}) => {
	const [swiperInstance, setSwiperInstance] = useState(null);
	const isTouch =
		swiperInstance &&
		(swiperInstance.device.ios || swiperInstance.device.android)
			? true
			: false;

	let slideMedia = null;
	let slidesPosition = 0;
	const gridPadding =
		typeof window == `undefined` ? 0 : window.innerWidth * 0.1;

	const onUpdate = () => {
		if (!swiperInstance) return false;

		if (swiperInstance.translate !== slidesPosition) {
			slidesPosition = swiperInstance.translate;
			return true;
		}

		return false;
	};

	const onRender = () => {
		const slides = swiperInstance.slides;
		const slideOffsets = swiperInstance.slidesGrid;
		const current = Math.abs(swiperInstance.translate);
		const slideWidth = swiperInstance.width;
		const slideScale = 0.2;

		for (let i = 0; i < slides.length; i++) {
			const slideOffset = current - slideOffsets[i];
			const slideProgress = slideOffset / slideWidth;
			let scale = 1 - slideProgress * slideScale;
			const child = slides[i].children[0];
			const media = slideMedia[i];

			if (scale > 1 || scale < 0.6) scale = 1;

			let translate = 100 * (1 - scale);

			if (child) {
				let transform = `scale(${scale}) translateX(${translate}%)`;
				child.style.transform = transform;
			}

			if (media) {
				media.style.opacity = slideProgress;
			}
		}
	};

	useEffect(() => {
		if (swiperInstance) {
			slideMedia = swiperInstance.$wrapperEl[0].querySelectorAll(
				"figure:last-child"
			);

			const animation = AnimationController.set({
				update: onUpdate,
				render: onRender,
			});

			return () => {
				AnimationController.clear(animation);
			};
		}
	}, [swiperInstance]);

	return (
		<div className={classes.container}>
			<div className={classes.navigationSuperWrapper}>
				<div className={classes.navigationWrapper}>
					<CarouselNavigation
						swiperInstance={swiperInstance}
						theme="light"
						numSlides={caseStudies.length}
					/>
				</div>
				<div className={classes.navigationCtaWrapper}>
					<CTAInlineDash
						url="/work/"
						label="View All"
						data-cursor-layer="main"
						data-cursor-face="dot"
						data-cursor-hitbox=""
						data-cursor-disabled="false"
					/>
				</div>
			</div>
			<div
				className={classes.slides}
				data-cursor-layer="main"
				data-cursor-face="drag"
				data-cursor-hitbox=""
				data-cursor-disabled="false"
			>
				<div className={classes.slidesMain}>
					<Swiper
						className={classes.swiperWrapper}
						onSwiper={setSwiperInstance}
						touchStartPreventDefault={!!isTouch}
						speed={1000}
						longSwipesRatio={0.15}
						loop={true}
						slidesPerView={1.1}
						slidesOffsetBefore={gridPadding}
						onResize={instance => {
							instance.params.slidesOffsetBefore = window.innerWidth * 0.1;
							instance.update();
						}}
						onSlideChange={instance => {
							setTimeout(() => {
								window.dispatchEvent(new Event("scroll"));
							}, 100);
						}}
					>
						{caseStudies.map((slide, i) => (
							<SwiperSlide key={i}>
								<div className={classes.slide}>
									<div className={classes.slideText}>
										<span className="eyebrow">
											{slide.caseStudyPreviewContent.eyebrow}
										</span>
										<p className="h1">
											<Link to={pathJoin(["work", slide.url])}>
												/ {slide.caseStudyPreviewContent.headline}
											</Link>
										</p>
									</div>
									<div className={classes.slideMedia}>
										<Asset
											src={slide.caseStudyPreviewContent.image}
											alt={
												slide.caseStudyPreviewContent.alt ||
												slide.caseStudyPreviewContent.headline
											}
											vimeoId={slide.caseStudyPreviewContent.vimeoId}
											params="fit=crop"
											width={982}
											height={480}
										/>
										<Asset
											src={slide.caseStudyPreviewContent.image}
											alt={slide.caseStudyPreviewContent.alt || ""}
											vimeoId={slide.caseStudyPreviewContent.vimeoId}
											params="blur=100&fit=crop"
											width={982}
											height={480}
										/>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default FeaturedWorkCarousel;
