import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import classNames from "classnames";

import CTAArrowRound from "../ui/ctas/arrow-round";
import ArrowRight from "../../assets/vectors/icons/arrow-right.svg";

import classes from "./styles.module.scss";

export interface PrefooterInterface {
	body_copy: string;
	button_copy: string;
	button_link: string;
	eyebrow: string;
	show_newsletter_signup: boolean;
	sub_headline: string;
}

const PreFooter: React.FC<{
	eyebrow: string;
	sub_headline: string;
	button_copy: string;
	button_link: string;
	body_copy: string;
	show_newsletter_signup: boolean;
}> = ({
	eyebrow,
	sub_headline,
	button_copy,
	button_link,
	body_copy,
	show_newsletter_signup,
}) => {
	return (
		<div className={classes.superWrapper}>
			<div className={classes.wrapper}>
				<span className={classNames("eyebrow", classes.eyebrow)}>
					{eyebrow}
				</span>
				<h2 className={classes.heading}>{sub_headline}</h2>
				{!show_newsletter_signup && (
					<CTAArrowRound label={button_copy} mailto={button_link} />
				)}
				{show_newsletter_signup && (
					<form onSubmit={e => e.preventDefault()} className={classes.form}>
						<input type="email" placeholder="yourname@email.com" />
						<button type="submit">
							<ArrowRight />
						</button>
					</form>
				)}
			</div>
		</div>
	);
};

export default PreFooter;
