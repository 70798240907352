import React from "react";
import parse from "html-react-parser";
import classnames from "classnames";

import classes from "./styles.module.scss";

import StandardCarousel from "../standard";
import StackedTitle from "../../text-blocks/stacked-title";
import Asset from "../../ui/asset";

interface GalleryItem {
	alt: string;
	caption: string;
	image: string;
	vimeoId: string;
}

const DesignCompsCarousel: React.FC<{
	index: number;
	heading?: string;
	bodyText?: string;
	heroImage?: string;
	heroImageAlt?: string;
	vimeoId?: string;
	backgroundColor?: string;
	galleryItems: GalleryItem[];
	gapBetweenSlides?: boolean;
}> = ({
	index,
	heading,
	bodyText = "",
	galleryItems,
	heroImage,
	backgroundColor,
	heroImageAlt,
	vimeoId,
	gapBetweenSlides = false,
}) => {
	return (
		<>
			<style>
				{`
          .${classes.container}[data-index="${index}"],
          .${classes.container}[data-index="${index}"] .${
					classes.textWrapper
				}:before { --background-color: ${
					backgroundColor ? backgroundColor : "var(--brand-black)"
				}; }
        `}
			</style>

			<div className={classes.container} data-index={index}>
				{heading && bodyText && (
					<div className={classes.textWrapper}>
						<StackedTitle heading={heading} theme="dark" bodyText={bodyText} />
					</div>
				)}
				{!vimeoId && heroImage && (
					<div className={classes.heroImage}>
						<Asset src={heroImage} alt={heroImageAlt} width={1440} />
					</div>
				)}
				{vimeoId && (
					<div className={classes.heroImage}>
						<Asset src={null} vimeoId={vimeoId} resizeVideo={false} />
					</div>
				)}
				{galleryItems.length === 1 && (
					<div className={classes.heroImage}>
						<Asset
							width={1020}
							src={`${galleryItems[0].image}?fm=auto&w=1200`}
							alt={galleryItems[0].alt}
							vimeoId={galleryItems[0].vimeoId}
							resizeVideo={false}
						/>
						<div className={classes.slideText}>
							{galleryItems[0].caption && (
								<div className={classnames("metadata", classes.slideCaption)}>
									{parse(galleryItems[0].caption)}
								</div>
							)}
						</div>
					</div>
				)}
				{galleryItems.length > 1 && (
					<div className={classes.slidesWrapper}>
						<StandardCarousel
							data-cursor-layer="main"
							data-cursor-face="drag"
							data-cursor-hitbox=""
							data-cursor-disabled="false"
							style={{ width: "min(80vh, 100%)" }}
							hasSpaceBetween={gapBetweenSlides}
						>
							{galleryItems.map((item, i) => {
								return (
									<div key={i} className={classes.slide}>
										<div className={classes.slideImage}>
											<Asset
												key={i}
												width={1020}
												src={`${item.image}`}
												alt={item.alt}
												vimeoId={item.vimeoId}
												resizeVideo={false}
											/>
										</div>
										<div className={classes.slideText}>
											{item.caption && (
												<div
													className={classnames(
														"metadata",
														classes.slideCaption
													)}
												>
													{parse(item.caption)}
												</div>
											)}
										</div>
									</div>
								);
							})}
						</StandardCarousel>
					</div>
				)}
			</div>
		</>
	);
};

export default DesignCompsCarousel;
