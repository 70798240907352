import React from "react";
import classnames from "classnames";

import classes from "./styles.module.scss";

const CTAPlay: React.FC<{
	className?: string;
	onClick?: () => void;
}> = props => {
	const { onClick } = props;

	return (
		<button className={classes.container} onClick={onClick} {...props}>
			<figure className={classes.icon}>
				<span></span>
				<span></span>
			</figure>
			<span className="visually-hidden">Play video</span>
		</button>
	);
};

export default CTAPlay;
