import React from "react";
import { Link } from "gatsby";

import { pathJoin } from "../../../utils";
import classes from "./styles.module.scss";
import Asset from "../../ui/asset";

interface Hero {
	image: string;
	alt: string;
	eyebrow: string;
}

interface PreviewContent {
	eyebrow: string;
	image: string;
	alt: string;
	feature_in_work_landing_page?: boolean;
}

interface Project {
	name: string;
	url: string;
	caseStudyHero: Hero;
	caseStudyPreviewContent: PreviewContent;
	show_as_project?: boolean;
}

const GridItem: React.FC<{
	url: string;
	image: string;
	eyebrow: string;
	heading: string;
	alt: string;
}> = ({ url, image, alt, eyebrow, heading }) => (
	<div className={classes.gridItem}>
		<div className={classes.gridItemText}>
			<p className="metadata">{eyebrow}</p>
			<p>
				<Link to={pathJoin(["/work", url])}>/ {heading}</Link>
			</p>
		</div>
		<div className={classes.gridItemImage}>
			<Asset width={549} height={366} src={image} alt={alt} params="fit=crop" />
		</div>
	</div>
);

const WorkLandingGrid: React.FC<{
	heading: string;
	subHeading: string;
	caseStudies: Project[];
	projects: Project[];
}> = ({ heading, subHeading, caseStudies, projects }) => {
	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<p className="eyebrow">{subHeading}</p>
				<h2>{heading}</h2>
			</div>
			<div className={classes.grid}>
				{caseStudies.map((item, i) => {
					if (item.caseStudyPreviewContent.feature_in_work_landing_page) {
						return (
							<GridItem
								key={i}
								url={item.url}
								eyebrow={item.caseStudyPreviewContent.eyebrow}
								heading={item.name}
								image={item.caseStudyPreviewContent.image}
								alt={item.caseStudyPreviewContent.alt}
							/>
						);
					}
				})}
				{projects.map((item, i) => {
					if (item.caseStudyPreviewContent.feature_in_work_landing_page) {
						return (
							<GridItem
								key={i}
								url={item.url}
								eyebrow={item.caseStudyPreviewContent.eyebrow}
								heading={item.name}
								image={item.caseStudyHero.image}
								alt={item.caseStudyHero.alt}
							/>
						);
					}
				})}
			</div>
		</div>
	);
};

export default WorkLandingGrid;
