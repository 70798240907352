import { bindEvents, getRelativeOffset } from "./index";
import easing from "./easing";

import AnimationController from "./controller-animation";
import ScrollController from "./controller-scroll";

let cursor = null;
let defaultLayer = null;
let activeLayer = null;

class CursorController {
	constructor() {
		if (typeof window == `undefined`) {
			return false;
		}

		this.selector = "";
		this.layers = {};

		this.cursorDot = null;

		this.touch = false;

		this.position = {
			easing: "easeOutQuart",
			duration: 10,
			actual: [window.innerWidth / 2, window.innerHeight / 2],
			from: [0, 0],
			to: [0, 0],
			current: [0, 0],
			frame: 0,
		};

		this.face = null;

		this.hijack = null;

		this.focus = null;
		this.distance = 0;

		this.threshold = 50;

		this.visible = false;

		this.progress = {
			length: 0,
			value: 0,
			updated: false,
		};

		this.animation = null;

		this.events = bindEvents(this);

		this.addEventListeners();
	}

	get cursor() {
		return cursor;
	}

	set cursor(el) {
		cursor = el;
		this.cursorDot = document.getElementById("cursor-dot");
		return true;
	}

	get defaultLayer() {
		return defaultLayer;
	}

	set defaultLayer(layer) {
		defaultLayer = layer;
		if (!this.activeLayer) this.activeLayer = layer;
		return true;
	}

	get activeLayer() {
		return activeLayer;
	}

	set activeLayer(layer) {
		activeLayer = layer;
		this.measureLayer();
		return true;
	}

	hideDefaultCursor() {
		document.documentElement.classList.add("no-default-cursor");
	}

	showDefaultCursor() {
		document.documentElement.classList.remove("no-default-cursor");
	}

	collectAll() {
		this.layers = {};
		const elements = document.querySelectorAll(this.selector);
		for (const el of elements) {
			const layer = el.dataset.cursorLayer;
			if (!this.layers[layer]) this.layers[layer] = [];
			this.layers[layer].push({
				el,
				face: el.dataset.cursorFace,
				fixed: el.hasAttribute("data-cursor-fixed"),
				hitbox: el.hasAttribute("data-cursor-hitbox"),
				measurements: {},
			});
		}
		this.measureLayer();
	}

	refreshLayer(id) {
		this.layers[id] = [];
		const elements = document.querySelectorAll(this.selector);
		for (const el of elements) {
			const layer = el.dataset.cursorLayer;
			if (layer === id) {
				this.layers[id].push({
					el,
					face: el.dataset.cursorFace,
					fixed: el.hasAttribute("data-cursor-fixed"),
					hitbox: el.hasAttribute("data-cursor-hitbox"),
					measurements: {},
				});
			}
		}
		this.measureLayer();
	}

	measureLayer(layer = this.activeLayer) {
		if (this.layers[layer]) {
			for (const d of this.layers[layer]) {
				d.measurements = {
					width: d.el.offsetWidth,
					height: d.el.offsetHeight,
					left: getRelativeOffset.left(d.el),
					top: getRelativeOffset.top(d.el),
				};
			}
		}
	}

	startHijack(face, click) {
		this.hijack = { face, click };
		this.focus = null;
	}

	cancelHijack(face) {
		if (this.hijack && face === this.hijack.face) this.hijack = false;
	}

	update() {
		let position = {
			current: ScrollController.eased,
			actual: ScrollController.top,
		};

		let hasLayers = Object.keys(this.layers).length > 0;
		let render = false;

		let to = this.position.actual;

		if (!hasLayers) {
			return;
		}

		if (this.hijack) {
			if (this.hijack.face !== this.face) render = true;

			this.face = this.hijack.face;
		} else {
			let cursorFace = null;

			let focus = null,
				distance = this.threshold,
				center = null;

			for (let i = 0, l = this.layers[this.activeLayer].length; i < l; ++i) {
				const d = this.layers[this.activeLayer][i];

				if (
					(!d.el.dataset.cursorDisabled ||
						d.el.dataset.cursorDisabled === "false") &&
					d.measurements.height > 0
				) {
					if (d.hitbox) {
						if (focus === null) {
							const hitbox = [
								d.measurements.left,
								d.measurements.left + d.measurements.width,
								d.measurements.top,
								d.measurements.top + d.measurements.height,
							];
							if (!d.fixed) {
								hitbox[2] -= position.current;
								hitbox[3] -= position.current;
							}
							if (d.el.dataset.cursorOffset) {
								const cursorOffset = parseInt(d.el.dataset.cursorOffset, 10);
								hitbox[2] += cursorOffset;
								hitbox[3] += cursorOffset;
							}
							if (
								to[0] > hitbox[0] &&
								to[0] < hitbox[1] &&
								to[1] > hitbox[2] &&
								to[1] < hitbox[3]
							) {
								cursorFace = d.face;
							}
						}
					} else {
						const c = [
							d.measurements.left + d.measurements.width / 2,
							d.measurements.top + d.measurements.height / 2,
						];
						if (!d.fixed) c[1] -= position.current;
						if (d.el.dataset.cursorOffset)
							c[1] += parseInt(d.el.dataset.cursorOffset, 10);

						const s = Math.sqrt(
							(to[0] - c[0]) ** 2 + (to[1] + position.actual - c[1]) ** 2
						);

						if (s < distance) {
							c[1] -= position.actual;

							cursorFace = d.face;
							focus = i;
							distance = s;
							center = c;
						}
					}
				}
			}

			if (center) {
				// to = [
				// 	center[0] + (this.position.actual[0] - center[0]) / 5,
				// 	center[1] + (this.position.actual[1] - center[1]) / 5,
				// ];

				to = [center[0], center[1]];
				this.position.to = to;
				this.position.current = to;
			}

			if (focus !== this.focus || cursorFace !== this.face) render = true;

			this.lastFocus = this.focus;
			this.focus = focus;
			this.face = cursorFace || "default";
		}

		if (this.progress.updated) render = true;

		if (to !== this.position.to) {
			const [x, y] = this.position.current;
			this.position.from[0] = x;
			this.position.from[1] = y;
			this.position.to = to;
			this.position.frame = this.position.duration;
		}

		if (this.position.frame > 0) {
			--this.position.frame;
			this.position.current[0] = Math.round(
				this.position.from[0] +
					easing[this.position.easing](
						this.position.duration - this.position.frame,
						0,
						this.position.to[0] - this.position.from[0],
						this.position.duration
					)
			);
			this.position.current[1] = Math.round(
				this.position.from[1] +
					easing[this.position.easing](
						this.position.duration - this.position.frame,
						0,
						this.position.to[1] - this.position.from[1],
						this.position.duration
					)
			);
			render = true;
		}

		return render;
	}

	render() {
		this.cursor.classList[this.visible ? "add" : "remove"]("is-visible");
		this.cursor.setAttribute("data-type", this.face);
		this.cursor.style.transform = `translate3d(${this.position.current[0]}px, ${this.position.current[1]}px, 0)`;
		this.cursorDot.style.transform = `translate3d(${this.position.current[0]}px, ${this.position.current[1]}px, 0)`;

		// for (let i = 0, l = this.layers[this.activeLayer].length; i < l; ++i) {
		// 	const { el } = this.layers[this.activeLayer][i];
		// 	if (this.focus === i) {
		// 		el.classList.add("has-focus");
		// 		el.style.transform = `translate3d(${Math.round(
		// 			(this.position.actual[0] - this.position.current[0]) / 5
		// 		)}px, ${Math.round(
		// 			(this.position.actual[1] - this.position.current[1]) / 5
		// 		)}px, 0)`;
		// 	} else {
		// 		el.classList.remove("has-focus");
		// 		el.style.transform = "";
		// 	}
		// }
	}

	start() {
		// this.hideDefaultCursor()
		this.animation = AnimationController.set(
			{
				update: this.update.bind(this),
				render: this.render.bind(this),
			},
			"cursor"
		);
	}

	pause() {
		AnimationController.clear(this.animation);
	}

	updateProgress(percentage) {
		if (percentage !== this.progress.value) {
			this.progress.value = percentage;
			this.progress.updated = true;
		}
	}

	remove() {
		this.pause();
		this.removeEventListeners();
		this.showDefaultCursor();
	}

	_onWindowLoad() {
		this.measureLayer(this.activeLayer);
	}

	_onWindowResize() {
		this.measureLayer(this.activeLayer);
	}

	_onDocumentMouseEnter() {
		this.visible = true;
	}

	_onDocumentMouseLeave() {
		this.visible = false;
	}

	_onDocumentMouseMove(e) {
		this.visible = true;
		this.position.actual = [e.clientX, e.clientY];
	}

	_onDocumentClick(e) {
		if (this.focus !== null) {
			e.preventDefault();
			e.stopPropagation();
			document.removeEventListener("click", this.events._onDocumentClick, true);
			const { el } = this.layers[this.activeLayer][this.focus];
			el.focus();
			el.click();
			document.addEventListener("click", this.events._onDocumentClick, true);
		} else if (this.hijack && this.hijack.click) {
			e.preventDefault();
			e.stopPropagation();
			this.hijack.click();
		}
	}

	addEventListeners() {
		window.addEventListener("load", this.events._onWindowLoad);
		window.addEventListener("resize", this.events._onWindowResize);

		document.addEventListener("mouseleave", this.events._onDocumentMouseLeave);
		document.addEventListener("mouseenter", this.events._onDocumentMouseEnter);
		document.addEventListener("mousemove", this.events._onDocumentMouseMove);
		document.addEventListener("click", this.events._onDocumentClick, true);
	}

	removeEventListeners() {
		window.removeEventListener("load", this.events._onWindowLoad);
		window.removeEventListener("resize", this.events._onWindowResize);

		document.removeEventListener(
			"mouseleave",
			this.events._onDocumentMouseLeave
		);
		document.removeEventListener(
			"mouseenter",
			this.events._onDocumentMouseEnter
		);
		document.removeEventListener("mousemove", this.events._onDocumentMouseMove);
		document.removeEventListener("click", this.events._onDocumentClick, true);
	}
}

export default new CursorController();
