import React from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import StackedTitle from "../../text-blocks/stacked-title";
import ImageUrl from "../../../../static/images/fpo/mailchimp.jpg";

interface Expertise {
	body_copy: string;
	hover_logo: string;
	name: string;
	non_hover_logo: string;
}

const ExpertiseOverview: React.FC<{
	heading: string;
	bodyText: string;
	subheading: string;
	expertise: Expertise[];
}> = ({ heading, bodyText, subheading, expertise }) => {
	const remainder = expertise.length % 3;
	const numItemsToAdd = 3 - remainder;
	const itemsToAdd = [];

	for (let i = 0; i < numItemsToAdd; i++) {
		itemsToAdd.push(i);
	}

	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<StackedTitle heading={heading} bodyText={bodyText} />
			</div>
			<div className={classes.expertise}>
				<p className="h5">{subheading}</p>
				<ul>
					{expertise.map((item, i) => (
						<li key={i}>
							<div className={classes.expertiseContent}>
								<div className={classes.expertiseHover}>
									<img
										src={item.hover_logo}
										alt={item.name}
										width="161"
										height="auto"
										loading="lazy"
									/>
									<div
										className={classNames(classes.expertiseText, "metadata")}
									>
										{parse(item.body_copy)}
									</div>
								</div>
								<div className={classes.expertiseLogo}>
									<img
										src={item.non_hover_logo}
										alt={item.name}
										loading="lazy"
										width="161"
										height="auto"
									/>
								</div>
							</div>
						</li>
					))}
					{new Array(itemsToAdd).map((item, i) => (
						<li key={i}></li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ExpertiseOverview;
