import React from "react";
import parse from "html-react-parser";
import classnames from "classnames";
import { Link } from "gatsby";

import classes from "./styles.module.scss";

import ParallaxImage from "../ui/parallax-image";
import CTAArrowRound from "../ui/ctas/arrow-round";

interface Subcategory {
	name: string;
	url: string;
}

const ServicesLandingCategory: React.FC<{
	heading: string;
	body: string;
	cta: string;
	ctaUrl: string;
	baseUrl: string;
	subcategories: Subcategory[];
	imageLayout: number;
	image1: string;
	vimeoId1: string;
	image1Alt: string;
	image2: string;
	vimeoId2: string;
	image2Alt: string;
	image3: string;
	vimeoId3: string;
	image3Alt: string;
}> = ({
	heading,
	body,
	cta,
	ctaUrl,
	baseUrl,
	subcategories,
	imageLayout,
	image1,
	vimeoId1,
	image1Alt,
	image2,
	vimeoId2,
	image2Alt,
	image3,
	vimeoId3,
	image3Alt,
}) => {
	return (
		<div className={classes.container}>
			<div className={classes.textWrapper}>
				<h2 className="h3">
					<span>{heading}</span>
				</h2>
				<div className={classes.bodyText}>{parse(body)}</div>
				<CTAArrowRound label={cta} url={ctaUrl} />
				<ul className="metadata-large">
					{subcategories.map((subcat, i) => (
						<li key={i}>
							<Link to={`/services${baseUrl}${subcat.url}`}>{subcat.name}</Link>
						</li>
					))}
				</ul>
			</div>
			<div
				className={classnames(
					classes.mediaWrapper,
					classes[`imageLayout${imageLayout}`]
				)}
			>
				<div className={classes.imageWrapper}>
					<ParallaxImage
						width={303}
						height={351}
						src={image1}
						vimeoId={vimeoId1}
						alt={image1Alt}
						maxShift={1.6}
						params="fit=crop&crop=focalpoint"
					/>
				</div>
				<div className={classes.imageWrapper}>
					<ParallaxImage
						width={351}
						height={478}
						src={image2}
						vimeoId={vimeoId2}
						alt={image2Alt}
						params="fit=crop&crop=focalpoint"
					/>
				</div>
				<div className={classes.imageWrapper}>
					<ParallaxImage
						width={447}
						height={287}
						src={image3}
						vimeoId={vimeoId3}
						alt={image3Alt}
						maxShift={1.5}
						params="fit=crop&crop=focalpoint"
					/>
				</div>
			</div>
		</div>
	);
};

export default ServicesLandingCategory;
