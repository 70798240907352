import React, { useRef, useEffect } from "react";

import { scaleCanvas } from "../../utils";
import classes from "./styles.module.scss";

import Arrow from "../../assets/vectors/icons/arrow-right-large.svg";

const FourOhFourModule = () => {
	const wrapperElement = useRef(null);
	const gameCanvas = useRef(null);
	const buttonReset = useRef(null);
	const scoreCounter = useRef(null);
	const buttonUp = useRef(null);
	const buttonDown = useRef(null);
	const buttonLeft = useRef(null);
	const buttonRight = useRef(null);

	useEffect(() => {
		let isInitialized = false;
		const board_background = "#000000";
		const snake_col = "#ffffff";

		let snake = [
			{ x: 200, y: 200 },
			{ x: 190, y: 200 },
			{ x: 180, y: 200 },
			{ x: 170, y: 200 },
			{ x: 160, y: 200 },
			{ x: 150, y: 200 },
			{ x: 140, y: 200 },
		];

		let score = 0;
		// True if changing direction
		let changing_direction = false;
		let food_x;
		let food_y;

		// Horizontal velocity
		let dx = 10;
		// Vertical velocity
		let dy = 0;

		// Get the canvas element
		const snakeboard = gameCanvas?.current;

		// Return a two dimensional drawing context
		const snakeboard_ctx = snakeboard.getContext("2d");

		const width = wrapperElement.current.offsetWidth;
		const height = wrapperElement.current.offsetHeight;

		scaleCanvas(gameCanvas.current, snakeboard_ctx, width, height);

		const reset = () => {
			snake = [
				{ x: 200, y: 200 },
				{ x: 190, y: 200 },
				{ x: 180, y: 200 },
				{ x: 170, y: 200 },
				{ x: 160, y: 200 },
				{ x: 150, y: 200 },
				{ x: 140, y: 200 },
			];

			dx = 10;
			dy = 0;
			changing_direction = false;
			score = 0;

			gen_food();

			clear_board();
			drawFood();
			move_snake();
			drawSnake();

			buttonReset.current.setAttribute("disabled", "disabled");
		};

		gen_food();

		document.addEventListener("keydown", change_direction);

		buttonUp.current.addEventListener("click", direction_up);
		buttonDown.current.addEventListener("click", direction_down);
		buttonLeft.current.addEventListener("click", direction_left);
		buttonRight.current.addEventListener("click", direction_right);

		// draw once
		clear_board();
		drawFood();
		move_snake();
		drawSnake();

		// main function called repeatedly to keep the game running
		function main() {
			if (has_game_ended()) {
				buttonReset.current.removeAttribute("disabled");
				return;
			}

			changing_direction = false;
			setTimeout(function onTick() {
				clear_board();
				drawFood();
				move_snake();
				drawSnake();
				// Repeat
				main();
			}, 100);
		}

		buttonReset.current.addEventListener("click", () => {
			reset();
			main();
		});

		// draw a border around the canvas
		function clear_board() {
			//  Select the colour to fill the drawing
			snakeboard_ctx.fillStyle = board_background;
			// Draw a "filled" rectangle to cover the entire canvas
			snakeboard_ctx.fillRect(0, 0, snakeboard.width, snakeboard.height);
		}

		// Draw the snake on the canvas
		function drawSnake() {
			// Draw each part
			snake.forEach(drawSnakePart);
		}

		function drawFood() {
			snakeboard_ctx.fillStyle = "#8AD124";
			// snakeboard_ctx.fillRect(food_x, food_y, 10, 10);
			snakeboard_ctx.beginPath();
			snakeboard_ctx.arc(food_x + 4, food_y + 4, 4, 0, 2 * Math.PI, false);
			snakeboard_ctx.fill();
		}

		// Draw one snake part
		function drawSnakePart(snakePart) {
			// Set the colour of the snake part
			snakeboard_ctx.fillStyle = snake_col;
			// Draw a circle to represent the snake part at the coordinates
			// the part is located
			snakeboard_ctx.strokestyle = board_background;

			snakeboard_ctx.beginPath();
			snakeboard_ctx.arc(
				snakePart.x + 4,
				snakePart.y + 4,
				4,
				0,
				2 * Math.PI,
				false
			);
			snakeboard_ctx.fill();

			// Draw a border around the snake part
			snakeboard_ctx.stroke();
		}

		function has_game_ended() {
			for (let i = 4; i < snake.length; i++) {
				if (snake[i].x === snake[0].x && snake[i].y === snake[0].y) return true;
			}
			const hitLeftWall = snake[0].x < 0;
			const hitRightWall = snake[0].x > snakeboard.width - 10;
			const hitToptWall = snake[0].y < 0;
			const hitBottomWall = snake[0].y > snakeboard.height - 10;
			return hitLeftWall || hitRightWall || hitToptWall || hitBottomWall;
		}

		function random_food(min, max) {
			return Math.round((Math.random() * (max - min) + min) / 10) * 10;
		}

		function gen_food() {
			// Generate a random number the food x-coordinate
			food_x = random_food(0, snakeboard.width - 10);
			// Generate a random number for the food y-coordinate
			food_y = random_food(0, snakeboard.height - 10);
			// if the new food location is where the snake currently is, generate a new food location
			snake.forEach(function has_snake_eaten_food(part) {
				const has_eaten = part.x === food_x && part.y === food_y;
				if (has_eaten) gen_food();
			});
		}

		function direction_up(event) {
			event.preventDefault();

			if (!isInitialized) {
				isInitialized = true;
				main();
			}

			const goingDown = dy === 10;
			if (!goingDown) {
				dx = 0;
				dy = -10;
			}
		}

		function direction_down(event) {
			event.preventDefault();

			if (!isInitialized) {
				isInitialized = true;
				main();
			}

			const goingUp = dy === -10;
			if (!goingUp) {
				dx = 0;
				dy = 10;
			}
		}

		function direction_left(event) {
			event.preventDefault();

			if (!isInitialized) {
				isInitialized = true;
				main();
			}

			const goingRight = dx === 10;

			if (!goingRight) {
				dx = -10;
				dy = 0;
			}
		}

		function direction_right(event) {
			event.preventDefault();

			if (!isInitialized) {
				isInitialized = true;
				main();
			}

			const goingLeft = dx === -10;
			if (!goingLeft) {
				dx = 10;
				dy = 0;
			}
		}

		function change_direction(event) {
			const LEFT_KEY = 37;
			const RIGHT_KEY = 39;
			const UP_KEY = 38;
			const DOWN_KEY = 40;

			// Prevent the snake from reversing
			if (changing_direction) return;
			changing_direction = true;

			const keyPressed = event.keyCode;
			const goingUp = dy === -10;
			const goingDown = dy === 10;
			const goingRight = dx === 10;
			const goingLeft = dx === -10;

			if (
				(keyPressed === LEFT_KEY ||
					keyPressed === UP_KEY ||
					keyPressed === RIGHT_KEY ||
					keyPressed === DOWN_KEY) &&
				!isInitialized
			) {
				isInitialized = true;
				main();
			}

			if (keyPressed === LEFT_KEY && !goingRight) {
				event.preventDefault();
				dx = -10;
				dy = 0;
			}

			if (keyPressed === UP_KEY && !goingDown) {
				event.preventDefault();
				dx = 0;
				dy = -10;
			}

			if (keyPressed === RIGHT_KEY && !goingLeft) {
				event.preventDefault();
				dx = 10;
				dy = 0;
			}

			if (keyPressed === DOWN_KEY && !goingUp) {
				event.preventDefault();
				dx = 0;
				dy = 10;
			}
		}

		function move_snake() {
			// Create the new Snake's head
			const head = { x: snake[0].x + dx, y: snake[0].y + dy };

			// Add the new head to the beginning of snake body
			snake.unshift(head);

			const has_eaten_food = snake[0].x === food_x && snake[0].y === food_y;

			if (has_eaten_food) {
				// Increase score
				score += 1;

				// Display score on screen
				scoreCounter.current.innerText = ("0" + score).slice(-2);

				// Generate new food location
				gen_food();
			} else {
				// Remove the last part of snake body
				snake.pop();
			}
		}
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.contentWrapper}>
				<h1 className={classes.heading}>
					404 Error &mdash; <span>Page not found</span>
				</h1>
				<p className="metadata">
					You were looking for a page on our website, but you found a game of
					Snake. You can thank us later, or use the navigation to get where you
					wanted to go.
				</p>
			</div>
			<div ref={wrapperElement} className={classes.canvasContainer}>
				<canvas
					id="gameCanvas"
					ref={gameCanvas}
					width="1000"
					height="500"
					style={{ width: "500px", height: "250px" }}
				/>
			</div>
			<div className={classes.gameControls}>
				<div className={classes.gameControlReset}>
					<button ref={buttonReset} disabled="disabled">
						Play Again
					</button>
					<p>Tap to Restart</p>
				</div>
				<div className={classes.gameControlScore}>
					<span ref={scoreCounter}>00</span>
					<p>Player Score</p>
				</div>
				<div className={classes.gameControlArrows}>
					<div>
						<button ref={buttonUp} className={classes.buttonUp}>
							<span>Up</span>
							<span>
								<Arrow />
							</span>
						</button>
					</div>
					<div>
						<button ref={buttonLeft} className={classes.buttonLeft}>
							<span>Left</span>
							<span>
								<Arrow />
							</span>
						</button>
						<button ref={buttonDown} className={classes.buttonDown}>
							<span>Down</span>
							<span>
								<Arrow />
							</span>
						</button>
						<button ref={buttonRight} className={classes.buttonRight}>
							<span>Right</span>
							<span>
								<Arrow />
							</span>
						</button>
					</div>
					<p>
						<span className={classes.desktopHidden}>Touch</span>
						<span className={classes.mobileHidden}>Keyboard</span>
						<span>Controls</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default FourOhFourModule;
