import React from 'react'
import { Link } from 'gatsby'

import classes from './styles.module.scss'

const CTAUnderline:React.FC<{ label:String, url:String }> = ({ label, url }) => (
    <Link to={ url } className={ classes.container }>
        <span className={ classes.label }>{ label }</span>
        <span className={ classes.icon }></span>
    </Link>
)

export default CTAUnderline
