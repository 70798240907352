import React from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
import HeroText from "../../text-blocks/hero-text";

const ServiceDetailHero: React.FC<{
	heading: string;
	eyebrow: string;
	body: string;
	image: string;
	imageAlt: string;
	vimeoId: string;
	theme?: string;
}> = ({ heading, eyebrow, body, image, imageAlt, vimeoId, theme }) => (
	<div
		className={classNames(
			classes.container,
			theme === "light" && classes.light
		)}
	>
		<div className={classes.heading}>
			<HeroText eyebrow={eyebrow} heading={heading} bodyText={parse(body)} />
		</div>
		<div className={classes.mediaWrapper}>
			<div className={classes.media}>
				<Asset
					width={447}
					height={566}
					src={image}
					alt={imageAlt}
					vimeoId={vimeoId}
				/>
			</div>
		</div>
	</div>
);

export default ServiceDetailHero;
