import React, { useState } from "react";

import classes from "./styles.module.scss";

import CTAArrowRound from "../../ui/ctas/arrow-round";
import LogoUrl from "../../../../static/images/fpo/webbys.png";
import IconPlus from "../../../assets/vectors/icons/plus.svg";
import ScrollController from "../../../utils/controller-scroll";

interface Award {
	alt: string;
	client: string;
	logo: string;
	name: string;
}

const AwardsOverview: React.FC<{
	heading: string;
	cta: string;
	awards: Award[];
}> = ({ heading, cta, awards }) => {
	const [expanded, setExpanded] = useState(false);
	const limit = expanded ? awards.length : 5;

	const onExpandClick = () => {
		setExpanded(true);
		requestAnimationFrame(() => {
			ScrollController.resize();
		});
	};

	return (
		<div className={classes.container}>
			<p className={classes.heading}>
				<span className="h5">{heading}</span>
				<span className="metadata">{awards.length} &amp; Counting</span>
			</p>
			<ul>
				{awards.slice(0, limit).map((item, i) => (
					<li key={i}>
						<div className={classes.logo}>
							<img
								src={item.logo}
								alt={item.alt}
								loading="lazy"
								onLoad={() => {
									ScrollController.resize();
								}}
							/>
						</div>
						<div className={classes.infoWrapper}>
							<div className={classes.label}>
								<span className="metadata-large">{item.client}</span>
							</div>
							<div className={classes.projectName}>
								<span className="metadata-large">{item.name}</span>
							</div>
						</div>
					</li>
				))}
			</ul>
			{!expanded && (
				<div className={classes.cta}>
					<CTAArrowRound
						label={cta}
						onClick={onExpandClick}
						icon={<IconPlus />}
						cursorFace="plus"
					/>
				</div>
			)}
		</div>
	);
};

export default AwardsOverview;
