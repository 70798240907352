import React, { useState } from "react";

import MainNav from "../main-nav";
import MobileNav from "../mobile-nav";

export type NavItem = {
	label: string;
	url: string;
	description: string;
	name?: string;
};

const Header: React.FC<{
	theme: String;
	pathname: String;
	items: NavItem[];
	socialItems: NavItem[];
}> = ({ theme, pathname, items, socialItems }) => {
	const [mobileActive, setMobileActive] = useState(false);

	return (
		<>
			<MainNav
				theme={theme}
				pathname={pathname}
				toggleMobileNav={setMobileActive}
				items={items}
			/>
			<MobileNav
				theme={theme}
				active={mobileActive}
				toggleActive={setMobileActive}
				items={items}
				socialItems={socialItems}
			/>
		</>
	);
};

export default Header;
