import React, { useEffect, useState } from "react";
import slugify from "slugify";
import classNames from "classnames";
import { Link } from "gatsby";
import { Insight } from "../../../../interfaces/common";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
import { pathJoin } from "../../../utils";

const FeaturedInsightsGrid: React.FC<{ insights: Insight[] }> = ({
	insights,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div className={classes.background}></div>
			<div className={classes.items}>
				{insights.map((item, i) => (
					<div key={i} className={classes.item}>
						<div className={classes.imageWrapper}>
							<Asset
								width={475}
								height={475}
								params="fit=crop"
								src={item.insightsDetailPageHero.image}
								alt={item.insightsDetailPageHero.alt}
							/>
						</div>
						<div className={classes.textWrapper}>
							<p className="metadata">{item.type}</p>
							<Link
								to={pathJoin([
									"/insights/",
									slugify(item.type.toLowerCase()),
									item.url,
								])}
							>
								{item.name}
							</Link>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FeaturedInsightsGrid;
