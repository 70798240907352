import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PrefooterInterface } from "../components/pre-footer";

import ServiceDetailHero from "../components/heroes/service-detail";
import ServiceDetailExcerpt from "../components/excerpts/service-detail";
import RelatedContent from "../components/related-content";
import Testimonial from "../components/testimonials/testimonial";

import ModulesFromFields from "../utils/modules-from-fields";

const ApproachDetailPage: React.FC = ({ pageContext }) => {
	const { data } = pageContext;
	const {
		seo,
		prefooter,
		approacheshero,
		approachesoverviews,
		serviceRelatedProjects,
		servicetestimonials,
		fields,
	} = data;
	const Modules = ModulesFromFields(fields, { theme: "dark" });
	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="dark" prefooter={prefooter}>
				<ServiceDetailHero
					eyebrow={approacheshero.eyebrow}
					heading={approacheshero.headline}
					body={approacheshero.body_copy}
					image={approacheshero.image}
					imageAlt={approacheshero.alt}
					vimeoId={approacheshero.vimeoId}
					theme="light"
				/>
				{approachesoverviews && (
					<ServiceDetailExcerpt
						heading={approachesoverviews.headline}
						body={approachesoverviews.body_copy}
						theme="dark"
					/>
				)}

				<div className="sections">
					<>{Modules}</>
				</div>

				{/* {servicetestimonials && servicetestimonials.length > 0 && (
					<Testimonial
						theme="light"
						testimonials={servicetestimonials}
						flushTop={false}
					/>
				)} */}

				{serviceRelatedProjects && (
					<RelatedContent
						eyebrow={serviceRelatedProjects.eyebrow}
						heading={serviceRelatedProjects.headline}
						relatedItems={serviceRelatedProjects.ServiceRelatedProjects}
						urlBase="/work"
					/>
				)}
			</Layout>
		</>
	);
};

export default ApproachDetailPage;
