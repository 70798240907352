import HeightController from "./controller-height"

import easing from "./easing"
import { getRootOffset } from "./index"

class ScrollController {
	attributes = {
		sticky: "data-sticky",
	}

	classes = {
		isLocked: "is-locked",
	}

	ease = {
		duration: 45,
		function: "easeOutQuint",
		from: 0,
		frame: 0,
	}

	rendering = true

	container

	include = []

	children = []

	stickies = []

	viewport = 0

	height = 0

	sticky = {
		top: 0,
	}

	direction = 0
	top = 0
	eased = 0
	chunk = 0
	percent = 0

	jump = false

	initialize(container, include) {
		this.container = container
		this.include = include
		this.measureViewport()
		this.setStyles()
	}

	measureViewport() {
		this.viewport = window.innerHeight
		this.sticky.top = parseFloat(
			getComputedStyle(document.documentElement).getPropertyValue(
				"--sticky-top"
			)
		)
	}

	updateHeight() {
		if (this.children.length) {
			const lastChild = this.children.length - 1
			this.height =
				this.children[lastChild].offset + this.children[lastChild].height
			HeightController.adjust(this.height)
		}
	}

	gatherChildren() {
		this.children = []
		const elements = this.container.children
		// const elements = []
		// for (const selector of this.include) {
		// 	elements.push(...this.container.querySelectorAll(selector))
		// }
		for (const el of elements) {
			this.children.push({
				el,
				offset: 0,
				height: 0,
				translate: 0,
				initial: false,
				update: false,
			})
		}
	}

	gatherStickies() {
		this.stickies = []
		const stickies = document.querySelectorAll(`[${this.attributes.sticky}]`)
		for (const el of stickies) {
			this.stickies.push({
				el,
				offset: 0,
				height: 0,
				space: 0,
				translate: 0,
				adjustment: parseInt(el.dataset.adjustment) || 0,
				update: false,
				includeOffset: el.hasAttribute("data-sticky-offset"),
			})
		}
	}

	measureChildren() {
		for (const child of this.children) {
			child.offset = getRootOffset(child.el).top
			child.height = child.el.clientHeight
		}
	}

	measureStickies() {
		for (const sticky of this.stickies) {
			sticky.offset = getRootOffset(sticky.el).top
			sticky.height = sticky.el.clientHeight
			sticky.space = sticky.el.parentNode.clientHeight - sticky.el.offsetTop
			sticky.space -= sticky.includeOffset ? this.sticky.top / 2 : 0
		}
	}

	updateDirection() {
		const top = window.scrollY
		if (top > this.top) {
			this.direction = 1
		} else if (top === this.top) {
			this.direction = 0
		} else {
			this.direction = -1
		}
	}

	updateTopPrevious() {
		this.top = window.scrollY
		this.percent = this.top / (this.height - this.viewport)
	}

	updateJumpTo() {
		this.ease.from = this.top
		this.ease.frame = 1
	}

	updateEaseTo() {
		this.ease.from = this.eased
		this.ease.frame = this.ease.duration
	}

	updateChildren() {
		for (const child of this.children) {
			let visibility = null
			let translate = 0
			if (this.eased + this.viewport <= child.offset) {
				visibility = "hidden"
				translate = child.offset - this.viewport
			} else if (this.eased >= child.offset + child.height) {
				visibility = "hidden"
				translate = child.offset + child.height
			} else {
				visibility = "visible"
				translate = this.eased
			}
			if (visibility !== child.visibility || translate !== child.translate) {
				child.visibility = visibility
				child.translate = translate
				child.update = true
			}
		}
	}

	updateStickies() {
		for (const sticky of this.stickies) {
			const offset = sticky.includeOffset ? this.sticky.top : 0
			const height = sticky.includeOffset
				? sticky.height + this.sticky.top * 2
				: 0
			if (height > this.viewport) {
				const difference = height - this.viewport
				sticky.adjustment += this.chunk
				if (sticky.adjustment < 0) sticky.adjustment = 0
				if (sticky.adjustment > difference) sticky.adjustment = difference
			} else {
				sticky.adjustment = 0
			}
			let translate = 0
			if (this.eased < sticky.offset - offset + sticky.adjustment) {
				translate = 0
			} else if (
				this.eased >
				sticky.offset -
					offset +
					sticky.adjustment +
					sticky.space -
					sticky.height
			) {
				translate =
					sticky.space - sticky.height < 0 ? 0 : sticky.space - sticky.height
			} else {
				translate = this.eased - sticky.offset + offset - sticky.adjustment
			}
			if (translate !== sticky.translate) {
				sticky.translate = translate
				sticky.update = true
			}
		}
	}

	updateEased() {
		const eased = Math.round(
			this.ease.from +
				easing[this.ease.function](
					this.ease.duration - this.ease.frame,
					0,
					this.top - this.ease.from,
					this.ease.duration
				)
		)
		if (eased !== this.eased) {
			this.chunk = eased - this.eased
			this.eased = eased
			this.updateChildren()
			this.updateStickies()
			return true
		}
	}

	update() {
		if (window.scrollY !== this.top) {
			this.updateDirection()
			this.updateTopPrevious()
			if (this.jump) {
				this.jump = false
				this.updateJumpTo()
			} else {
				this.updateEaseTo()
			}
		}

		if (this.ease.frame > 0) {
			--this.ease.frame
			return this.updateEased()
		}
	}

	render() {
		if (this.rendering) {
			for (const child of this.children) {
				if (child.update) {
					child.update = false
					child.el.style.visibility = child.visibility
					child.el.style.transform = `matrix(1, 0, 0, 1, 0, -${child.translate})`
				}
			}
			for (const sticky of this.stickies) {
				if (sticky.update) {
					sticky.update = false
					sticky.el.style.transform = `matrix(1, 0, 0, 1, 0, ${sticky.translate})`
					sticky.el.dataset.adjustment = sticky.adjustment
				}
			}
		}
	}

	refresh(gather) {
		if (gather) {
			this.gatherChildren()
			this.gatherStickies()
		}

		this.adjust()
	}

	resize() {
		this.measureViewport()
		this.adjust()
	}

	adjust() {
		this.measureChildren()
		this.measureStickies()

		this.updateChildren()
		this.updateStickies()
		this.updateHeight()
	}

	setStyles() {
		this.container.style.width = "100%"
		this.container.style.position = "fixed"
		this.container.style.left = 0
		this.container.style.top = 0
	}

	removeStyles() {
		this.container.style.width = ""
		this.container.style.position = ""
		this.container.style.left = ""
		this.container.style.top = ""

		for (const child of this.children) {
			child.el.style.visibility = ""
			child.el.style.transform = ""
		}

		for (const sticky of this.stickies) {
			sticky.el.style.transform = ""
		}
	}

	scrollTo(top, jump) {
		this.jump = jump
		window.scrollTo(false, top)
	}

	lock() {
		document.documentElement.classList.add(this.classes.isLocked)
	}

	unlock() {
		document.documentElement.classList.remove(this.classes.isLocked)
	}

	holdPosition() {
		if (this.percent && this.rendering) {
			this.scrollTo((this.height - this.viewport) * this.percent, true)
		}
	}
}

export default new ScrollController()
