import React, { useEffect, useState } from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import ParallaxImage from "../../ui/parallax-image";
import HeroText from "../../text-blocks/hero-text";

const ServicesLandingHero: React.FC<{
	eyebrow: string;
	heading: string;
	bodyText: string;
	image1: string;
	alt1: string;
	image2: string;
	alt2: string;
	image3: string;
	alt3: string;
	image4: string;
	alt4: string;
}> = ({
	eyebrow,
	heading,
	bodyText,
	image1,
	alt1,
	image2,
	alt2,
	image3,
	alt3,
	image4,
	alt4,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	return (
		<div className={containerClass}>
			<div className={classes.heading}>
				<HeroText
					eyebrow={eyebrow}
					heading={heading}
					bodyText={parse(bodyText)}
				/>
			</div>
			<div className={classes.media}>
				<ParallaxImage
					width={558}
					height={360}
					src={image1}
					alt={alt1}
					maxShift={1.4}
					params="crop=faces,center&fit=crop"
				/>
				<ParallaxImage
					width={336}
					height={607}
					src={image2}
					alt={alt2}
					maxShift={1.2}
					params="crop=faces,center&fit=crop"
				/>
				<div className={classes.mediaWrapper}>
					<ParallaxImage
						width={245}
						height={245}
						src={image3}
						alt={alt3}
						maxShift={1.5}
						params="crop=faces,center&fit=crop"
					/>
					<ParallaxImage
						width={472}
						height={286}
						src={image4}
						alt={alt4}
						maxShift={1.45}
						params="crop=faces,center&fit=crop"
					/>
				</div>
			</div>
		</div>
	);
};

export default ServicesLandingHero;
