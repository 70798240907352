import React, { useState } from "react";
import classNames from "classnames";
import CTA from "../ui/ctas/arrow-round";
import classes from "./styles.module.scss";

const ImageBanner: React.FC<{
	image: string;
	eyebrow: string;
	headline: string;
	cta_label: string;
	cta_url: string;
	theme: string;
}> = ({ eyebrow, headline, image, cta_label, cta_url, theme }) => {
	return (
		<div className={classNames(classes.container)}>
			<div className={classNames(classes.wrapper)}>
				<img src={image} />
				<div className={classNames(classes.content)}>
					{eyebrow && (
						<span className={classNames(classes.eyebrow, ".eyebrow")}>
							{eyebrow}
						</span>
					)}
					{headline && (
						<h1 className={classNames(classes.headline)}>{headline}</h1>
					)}
					{cta_label && cta_url && (
						<CTA theme="light" url={cta_url} label={cta_label} />
					)}
				</div>
			</div>
			<div className={classNames(classes.mobileContent)}>
				{eyebrow && (
					<span className={classNames(classes.eyebrow, "eyebrow")}>
						{eyebrow}
					</span>
				)}
				{headline && (
					<h1 className={classNames(classes.headline)}>{headline}</h1>
				)}
				{cta_label && cta_url && (
					<CTA theme="light" url={cta_url} label={cta_label} />
				)}
			</div>
		</div>
	);
};

export default ImageBanner;
