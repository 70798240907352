import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import SodaLogo from "../../../../static/images/fpo/soda-icon.jpg";

interface ListItem {
	title;
	body_copy;
	logo;
	alt;
}

const CareersLandingExcerpt: React.FC<{
	heading: string;
	body: string;
	listItems: ListItem[];
}> = ({ heading, body, listItems }) => (
	<div className={classes.container}>
		<h2>{heading}</h2>
		<div className={classes.textWrapper}>
			<div className={classes.bodyText}>{parse(body)}</div>
			<div className={classes.details}>
				{listItems && (
					<ul className="metadata-large">
						{listItems.map((listItem, i) => {
							return (
								<li key={i}>
									<span>{listItem.title}</span>
									{!listItem.logo && parse(listItem.body_copy)}
									{listItem.logo && (
										<img src={listItem.logo} alt={listItem.alt} />
									)}
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	</div>
);

export default CareersLandingExcerpt;
