import AnimationController from "./controller-animation"
class HeightController {
	animation
	spacer

	#subscribers = []

	height = 0
	changed = false

	initialize(spacer) {
		this.spacer = spacer
		this.animation = AnimationController.set({
			update: this.#update,
			render: this.#render,
		})
	}

	#update = () => {
		if (this.changed) {
			for (const subscriber of this.#subscribers) {
				if (subscriber) subscriber()
			}
			this.changed = false
			return true
		}
	}

	#render = () => {
		this.spacer.style.height = `${this.height}px`
	}

	adjust(height) {
		if (height !== this.height) {
			this.height = height
			this.changed = true
		}
	}

	add(fn) {
		this.#subscribers.push(fn)
	}

	remove(fn) {
		for (let i = 0, l = this.#subscribers.length; i < l; ++i) {
			if (fn === this.#subscribers[i]) {
				delete this.#subscribers[i]
				break
			}
		}
	}
}

export default new HeightController()
