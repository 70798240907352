import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import WorkLandingHero from "../components/heroes/work-landing";
import WorkLandingTakeover from "../components/takeover-work-landing";
import WorkLandingGrid from "../components/grids/work-landing";

export const query = graphql`
	query WorkLandingPage {
		cms {
			work_landing_page {
				url
				seo {
					title
					description
					thumbnail
				}
				workLandingPageHero {
					headline
					body_copy
				}
				aditionalProjects {
					eyebrow
					headline
				}
				prefooter {
					eyebrow
					sub_headline
					body_copy
					button_copy
					button_link
					show_newsletter_signup
				}
				projects {
					name
					url
					show_as_project
					caseStudyHero {
						image
						eyebrow
					}
					caseStudyPreviewContent {
						eyebrow
						image
						feature_in_work_landing_page
						alt
					}
				}
			}
			projects {
				name
				url
				show_as_project
				caseStudyHero {
					image
					eyebrow
				}
				caseStudyPreviewContent {
					eyebrow
					image
					feature_in_work_landing_page
					alt
				}
			}
			case_studies {
				name
				url
				show_as_project
				caseStudyPreviewContent {
					eyebrow
					headline
					image
					alt
					vimeoId
					feature_in_work_landing_page
				}
				caseStudyProjectDetail {
					project_title
					project_url
					client_logo
					casestudyprojectdetailservices {
						name
						url
						category {
							name
							url
							##You can review all the available field in the Services_category Endpoint
						}
						##You can review all the available field in the service_category_details Endpoint
					}
				}
			}
			case_studies_lite {
				name
				feature_in_homepage
				url
				caseStudyHero {
					image
					eyebrow
					alt
				}
				caseStudyPreviewContent {
					eyebrow
					image
					alt
					feature_in_work_landing_page
				}
			}
		}
	}
`;

const WorkPage: React.FC = ({ data }) => {
	const page = data.cms.work_landing_page;
	const caseStudiesLite = data.cms.case_studies_lite;
	const featuredCaseStudies = data.cms.case_studies;
	const caseStudies = data.cms.work_landing_page.projects;
	const { seo, workLandingPageHero, aditionalProjects, prefooter } = page;
	console.log('caseStudiesLite', caseStudiesLite)
	return (
		<>
			<SEO
				title={seo?.title}
				description={seo?.description}
				image={seo?.thumbnail}
				article={false}
			/>
			<Layout theme="transparent" prefooter={prefooter}>
				<WorkLandingHero
					heading={workLandingPageHero.headline}
					subHeading={workLandingPageHero.body_copy}
				/>
				<WorkLandingTakeover projects={[...caseStudiesLite.filter(c => !!c.feature_in_homepage), ...featuredCaseStudies]} />
				<WorkLandingGrid
					heading={aditionalProjects.headline}
					subHeading={aditionalProjects.eyebrow}
					caseStudies={caseStudies}
					projects={caseStudiesLite}
				/>
			</Layout>
		</>
	);
};

export default WorkPage;
