import React from "react";
import parse from "html-react-parser";
import classNames from "classnames";

import classes from "./styles.module.scss";

const InsightsDetailExcerpt: React.FC<{
	heading: string;
	body: string;
	theme: string;
}> = ({ heading, body, theme }) => {
	return (
		<div
			className={classNames(
				classes.container,
				theme === "dark" && classes.darkVariant
			)}
		>
			<h2 className={classes.heading}>{heading}</h2>
			<div className={classes.bodyText}>{parse(body || "")}</div>
		</div>
	);
};

export default InsightsDetailExcerpt;
