import React from "react";

import HeadingTextBlock from "../components/text-blocks/heading";
import InsightsDetailTextBlock from "../components/text-blocks/insights-detail-body";
import InlineImageBlock from "../components/inline-image-block";
import ImageCarousel from "../components/carousels/image-carousel";
import FullWidthMedia from "../components/full-width-media";
import ImageGallery from "../components/carousels/image-gallery";
import SideBySide from "../components/side-by-side";
import Testimonial from "../components/testimonials/testimonial";
import ImageBanner from "../components/imageBanner/imageBanner";
import ApproachLogoSlider from "../components/approachLogoSlider/approachLogoSlider";

const allModules = {
	heading_field: HeadingTextBlock,
	sub_heading_field: HeadingTextBlock,
	body_copy_field: InsightsDetailTextBlock,
	inline_image_field: InlineImageBlock,
	gallery_with_captions: ImageCarousel,
	full_bleed_image_field: FullWidthMedia,
	gallery: ImageGallery,
	side_by_side: SideBySide,
	testimonial_field: Testimonial,
	imagebanner: ImageBanner,
	client_logo_slider: ApproachLogoSlider,
};

export default (fields, extraProps = {}) => {
	const modules = [];
	const fieldsJson = JSON.parse(fields);

	if (fieldsJson) {
		fieldsJson.forEach((module, i) => {
			if (allModules[module.layout]) {
				module.attributes.key = i;

				modules.push(
					React.createElement(allModules[module.layout], {
						...extraProps,
						...module.attributes,
					})
				);
			} else {
				modules.push(
					<div>
						<p>
							Module <code>{module.layout}</code> hasn't been created yet
						</p>
					</div>
				);
			}
		});
	}

	return modules;
};
