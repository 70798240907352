import React, { useState, useEffect } from "react";
import classNames from "classnames";

import classes from "./styles.module.scss";
import CTAPlay from "../ui/ctas/play";
import Asset from "../ui/asset";

const FullWidthMedia: React.FC<{
	image: string;
	alt: string;
	vimeoId: string;
	flushBottom?: Boolean;
	padRight?: Boolean;
	splitBackground?: Boolean;
}> = ({
	image,
	alt,
	vimeoId,
	flushBottom = false,
	padRight = false,
	splitBackground = false,
}) => {
	const [playing, setPlaying] = useState(false);
	const [isMouseMoved, setIsMouseMoved] = useState(false);

	let timer = null;

	const containerClass = classNames(classes.container, {
		[classes.flushBottom]: flushBottom,
		[classes.padRight]: padRight,
	});

	const onMouseMove = () => {
		setIsMouseMoved(true);

		timer = setTimeout(() => {
			setIsMouseMoved(false);
		}, 2000);
	};

	useEffect(() => {
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, []);

	return (
		<div className={containerClass}>
			<div className={classes.mediaWrapper}>
				{vimeoId && (
					<>
						<div
							className={classNames(
								classes.thumbnail,
								playing ? classes.isPlaying : classes.isPaused,
								playing && isMouseMoved ? classes.isMousedOver : ""
							)}
							onMouseMove={onMouseMove}
							onClick={() => {
								setPlaying(!playing);
							}}
						>
							<CTAPlay
								onClick={() => {
									setPlaying(!playing);
								}}
							/>
						</div>
					</>
				)}
				<div className={classNames(classes.media, vimeoId ? "with-video" : "")}>
					<Asset
						src={image}
						alt={alt}
						vimeoId={vimeoId}
						autoplay={false}
						playing={playing}
					/>
				</div>
			</div>

			{splitBackground && <span className={classes.background}></span>}
		</div>
	);
};

export default FullWidthMedia;
