import React from "react";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import CTAArrowRound from "../../ui/ctas/arrow-round";

const CareersBlurb: React.FC<{
	heading: string;
	body: string;
	cta: string;
	ctaUrl: string;
}> = ({ heading, body, cta, ctaUrl }) => (
	<div className={classes.container}>
		<h2>{heading}</h2>
		<div className={classes.bodyText}>{parse(body)}</div>
		<CTAArrowRound label={cta} url={ctaUrl} />
	</div>
);

export default CareersBlurb;
