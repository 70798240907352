import React, { useEffect, useState } from "react";
import classNames from "classnames";
import parse from "html-react-parser";

import classes from "./styles.module.scss";

import Asset from "../../ui/asset";
import CTAInlineDash from "../../ui/ctas/inline-dash";

const HeroHome: React.FC<{
	heading?: string;
	subhead?: string;
	bodyText?: string;
	cta?: string;
	ctaUrl?: string;
	align?: string;
	subheadAlign?: string;
	bodyTextAlign?: string;
	foregroundAlignX?: string;
	foregroundAlignY?: string;
	foregroundImage?: string;
	backgroundImage?: string;
	backgroundVideo?: string;
	middlegroundImage?: string;
	backgroundColor?: string;
	foregroundImageAlt?: string;
	backgroundImageAlt?: string;
	middlegroundImageAlt?: string;
	foregroundImageWidth?: number;
	foregroundImageHeight?: number;
	middlegroundImageWidth?: number;
	middlegroundImageHeight?: number;
	loop?: boolean;
}> = ({
	heading,
	subhead,
	bodyText,
	bodyTextAlign,
	align,
	subheadAlign,
	foregroundAlignX = "center",
	foregroundAlignY = "center",
	foregroundImage,
	backgroundImage,
	backgroundVideo,
	middlegroundImage,
	backgroundColor,
	foregroundImageAlt,
	backgroundImageAlt,
	middlegroundImageAlt,
	cta,
	ctaUrl,
	foregroundImageWidth,
	foregroundImageHeight,
	middlegroundImageWidth,
	middlegroundImageHeight,
	loop,
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const themes = {
		left: "",
		center: classes.alignCenter,
		right: classes.alignRight,
		foreground: {
			x: {
				left: classes.foregroundXLeft,
				right: classes.foregroundXRight,
				center: classes.foregroundXCenter,
			},
			y: {
				top: classes.foregroundYTop,
				bottom: classes.foregroundYBottom,
				center: classes.foregroundYCenter,
			},
		},
	};

	const containerClass = classNames(classes.container, {
		[classes.isVisible]: isVisible,
	});

	const foregroundClass = classNames(
		classes.foreground,
		themes.foreground.x[foregroundAlignX],
		themes.foreground.y[foregroundAlignY]
	);
	const foregroundWidth = (foregroundImageWidth / 1440) * 100 + "vw";

	return (
		<div className={containerClass}>
			<div className={classes.text}>
				{heading && (
					<h1 className={classNames("mega", classes.heading, themes[align])}>
						{parse(heading)}
					</h1>
				)}
				{subhead && (
					<p
						className={classNames(
							"h2",
							"heading-sans",
							classes.subhead,
							themes[subheadAlign]
						)}
					>
						{parse(subhead)}
					</p>
				)}
				{bodyText && (
					<p className={classNames(classes.bodyText, themes[bodyTextAlign])}>
						{parse(bodyText)}
					</p>
				)}
				{cta && ctaUrl && (
					<CTAInlineDash
						label="Play Reel"
						url="/work/"
						className={classes.cta}
					/>
				)}
			</div>
			{foregroundImage && (
				<div className={foregroundClass}>
					{/* <img src={foregroundImage} alt={foregroundImageAlt} /> */}
					<div style={{ width: foregroundWidth }}>
						<Asset
							width={354}
							height={600}
							src={foregroundImage}
							alt={foregroundImageAlt}
						/>
					</div>
				</div>
			)}
			{middlegroundImage && (
				<div className={classes.middleground}>
					<img
						src={middlegroundImage}
						alt={middlegroundImageAlt}
						loading="lazy"
					/>
				</div>
			)}
			{(backgroundVideo || backgroundImage || backgroundColor) && (
				<div
					className={classes.background}
					style={{ backgroundColor: backgroundColor }}
				>
					{(backgroundVideo || backgroundImage) && (
						<Asset
							width={backgroundVideo ? 1920 : 1440}
							height={backgroundVideo ? 1080 : 1024}
							src={backgroundImage}
							alt={backgroundImageAlt}
							vimeoId={backgroundVideo}
							resizeVideo={true}
							loop={loop}
							posterImage={backgroundImage}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default HeroHome;
