import React, { Props } from "react";
import parse from "html-react-parser";

import { Media } from "../../../../interfaces/common";
import Asset from "../../ui/asset";

import classes from "./styles.module.scss";

import LogoUrl from "../../../../static/images/fpo/logo-glassbaby.png";

type Logo = {
	hexCode: string;
};

export interface TestimonialWithImageInterface {
	heading: string;
	eyebrow: string;
	body: string;
	logo: Logo;
	media: Media;
}

const TestimonialWithImage: React.FC<TestimonialWithImageInterface> = ({
	heading,
	eyebrow,
	body,
	media,
	logo,
}) => {
	const bodyParsed = parse(body);

	return (
		<div className={classes.container}>
			<div className={classes.textWrapper}>
				<p className="h1">{heading}</p>
				<p className="metadata-large">{eyebrow}</p>
				<q cite={`${heading}: ${eyebrow}`}>{body && bodyParsed}</q>
			</div>
			<div className={classes.imageWrapper}>
				<Asset width={300} height={300} src={media?.image} />
				{logo && logo.hexCode && (
					<div
						className={classes.logo}
						style={{ backgroundColor: logo?.hexCode }}
					>
						<img src={LogoUrl} alt="Glassbaby" />
					</div>
				)}
			</div>
		</div>
	);
};

export default TestimonialWithImage;
