import React from "react";

import { twoDigitFormat } from "../../../utils";
import classes from "./styles.module.scss";

import StackedTitle from "../stacked-title";

interface Item {
	active: boolean;
	name: string;
	description: string;
}

const DescriptionList: React.FC<{
	bodyText: string;
	heading: string;
	listItems: Item[];
}> = ({ heading, bodyText, listItems }) => {
	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<div className={classes.bodyText}>
					<StackedTitle heading={heading} bodyText={bodyText} />
				</div>
			</div>
			<div className={classes.listWrapper}>
				<ol>
					{listItems.map((item, i) => {
						if (item.active) {
							return (
								<li key={i} className="metadata-large">
									<em className="h6">{twoDigitFormat(i + 1)}</em>
									<span className="h5">{item.name}</span>
									<p>{item.description}</p>
								</li>
							);
						}
					})}
				</ol>
			</div>
		</div>
	);
};

export default DescriptionList;
